.logo {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0 auto;

    background-image: svg-load('../images/layout/svg/subpage-logo-camps.svg');
    background-repeat: no-repeat;

    position: relative;
    background-size: cover;
    background-position: center;

    @include media-breakpoint-up(xl) {
        left: 10px;
    }

    @include media-breakpoint-down(xl) {
        left: 50%;
        transform: translateX(-50%);
    }

    @include media-breakpoint-down(sm) {
        height: 65px;
        background-position: top;
    }
}

.frontpage {

    .logo {

        @include media-breakpoint-down(sm) {
            left: 50%;
            top: 50%;
            transform: translateX(-50%);
        }

        .slogan {
            width: 100%;
            height: calc(100% / 4);
            position: absolute;
            bottom: -10px;
            background-image: svg-load('../images/layout/svg/frontpage-logo-slogan-camps.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            transition: opacity 1s, transform 1s;

            &.disapear {
                transform: translateY(-50%);
                opacity: 0;
                transition: opacity 1s, transform 1s;
            }
        }
    }
}

//.microsite:not(.show-camps-logo) .logo, .frontpage.microsite:not(.show-camps-logo) .logo {
//    background-image: none;
//    top: 50%;
//    transition: all 0.3s;
//
//    @include media-breakpoint-down(sm) {
//        height: 60px;
//    }
//}

//.scroll-direction--scrolled .microsite:not(.show-camps-logo) .logo {
//    height: 100%;
//    top: 0;
//    transition: all 0.3s;
//}

//.microsite:not(.show-camps-logo).form {
//    .logo {
//        top: 0 !important;
//        height: 100%;
//    }
//}