.area--teaser-container {
  position: relative;
  min-height: 450px;
  display: flex;

  .container-teaser {
    flex: 1 1 auto;
    padding-right: 30px;

    @include media-breakpoint-down(xl) {
      max-width: unset;
      padding-right: 0;
    }
  }

  .teaser-parent {
    flex: 0 0 auto;

    .area--teaser {
      position: sticky;
      top: 200px;
      padding-right: 0;
    }
  }

  .area--teaser {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);

    @include media-breakpoint-down(xl) {
      display: none;
    }
  }
}