/*** Variables ***/
$magazine--color: #333333;
$magazine--gutter: 30px;
$magazine--border-light: rgba(0, 0, 0, .1);
$magazine--bg-gray: #e4e4e4;

$magazine-item--color: $magazine--color;
$magazine-item--bg: $brand-lightgrey;

$magazine-header--color: #fff;

$magazine-btn--color: #fff;
$magazine-btn--color-hover: #fff;
$magazine-btn--bg: $brand-red;
$magazine-btn--bg-hover: $magazine--color;

$magazine-pagination--color: $text-color;
$magazine-pagination--border-color: $brand-red;
$magazine-pagination--bg: transparent;
$magazine-pagination--bg-active: $brand-red;

$icon-color-filter--gray: invert(18%) sepia(1%) saturate(0%) hue-rotate(11deg) brightness(97%) contrast(91%);
$icon-color-filter--white: saturate(0) brightness(0) invert(100%) opacity(1);;

/*** Styles ***/
.magazine {
  color: $magazine--color;

  // Magazine List
  &--list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -($magazine--gutter / 2);
    margin-right: -($magazine--gutter / 2);

    > .magazine--article {
      width: 50%;
      flex: 0 0 50%;

      @media (min-width: 992px) {
        width: 33.333%;
        flex-basis: 33.333%;
      }

      @media (max-width: 767px) {
        width: 100%;
        flex-basis: 100%;
      }
    }

    &.page-1 {
      > .magazine--article {
        &:nth-child(1),
        &:nth-child(2) {
          @media (min-width: 992px) {
            width: 50%;
            flex-basis: 50%;
          }

          .magazine--article--more {
            @media (min-width: 992px) {
              width: auto;
            }
          }
        }
      }
    }
  }

  &--pagination {
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-align: center;

    li {
      display: inline-block;
      margin-bottom: 4px;

      .current {
        color: $white;
        background: $magazine-pagination--bg-active;
      }

      a {
        display: block;
        width: 50px;
        height: 50px;
        border: 2px solid $magazine-pagination--border-color !important;
        border-radius: 100%;
        color: $magazine-pagination--color;
        line-height: 46px;
        font-size: 17px;
        transition: background-color .3s, color .3s;

        &:hover {
          background: $magazine-pagination--bg-active;
          color: $white;
        }
      }
    }
  }

  // Magazine Detail
  &--detail {
    header {
      .magazine--meta {
        padding: 0;
        margin: 0 0 40px;

        font-size: 16px;

        .magazine--icon {
          position: relative;
          top: 2px;
          width: 20px;
          height: 16px;
          vertical-align: baseline;
          filter: $icon-color-filter--gray;
        }
      }
    }
  }

  // Buttons
  &--btn {
    display: inline-block;
    padding: 12px 25px;
    width: 100%;
    line-height: 1.3;
    background: $magazine-btn--bg;
    color: $magazine-btn--color;
    border: none;
    border-radius: 40px;
    text-transform: uppercase;
    font-size: 13px;
    text-align: center;
    cursor: pointer;
    transition: background-color .3s, color .3s;

    &:hover {
      color: $magazine-btn--color-hover;
      background-color: $magazine-btn--bg-hover;
      padding: 12px 25px !important;

      .magazine--icon {
        &:hover {
          filter: $icon-color-filter--gray;
        }
      }
    }

    .magazine--icon {
      margin: 0 4px 0 -8px;
      filter: $icon-color-filter--white;
      transition: filter .3s;
      font-size: 16px;
      vertical-align: bottom;
    }
  }

  /*** Magazine Teaser ***/
  &--article {
    padding: 0 ($magazine--gutter / 2) $magazine--gutter;
    line-height: 1.15;

    a {
      text-decoration: none;
    }

    &--inner {
      position: relative;
      height: 100%;
      background: $magazine-item--bg;
      overflow: hidden;

      > * {
        width: 100%;
        flex: 0 0 100%;
      }

      &:hover {
        .magazine--article {
          &--media {
            img, video {
              transform: scale(1.1);
            }
          }

          &--more {
            background-color: $magazine-btn--bg-hover;
            color: $magazine-btn--color-hover;
          }
        }
      }
    }

    &--media {
      position: relative;
      overflow: hidden;

      &:before {
        display: block;
        content: '';
        padding-bottom: 56.25%;
        width: 100%;
        height: 0;
        pointer-events: none;
      }

      img, video {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform .3s;
      }

      header {
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 40px 30px 25px;
        width: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .4) 100%);

        &, h4 {
          color: $magazine-header--color;
        }

        h4 {

        }
      }
    }

    &--meta {
      margin-top: 10px;
      padding-top: 15px;
      font-size: 12px;
      border-top: 1px solid rgba($magazine-header--color, .4);
      white-space: nowrap;

      span {
        display: inline-block;
        vertical-align: bottom;
      }

      .magazine--icon {
        margin-right: 5px;
        width: 16px;
        height: 14px;
        vertical-align: bottom;
        filter: $icon-color-filter--white;
      }
    }

    &--text {
      padding: 30px 30px 25px;
      font-size: 16px;
      color: $magazine-item--color;
      line-height: 1.5625;

      > p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &--buttons {
      padding: 0 30px 40px;
      text-align: right;
      align-self: flex-end;
    }

    &--more {

    }
  }

  &--categories {
    margin-bottom: 40px;

    .magazine--btn {
      background: transparent;
      color: $brand-red;
      border: 1px solid $brand-red !important;

      &:hover {
        border: 1px solid $brand-red !important;
        background: $brand-red;
        color: $white;
      }

      &.is-active {
        background: $brand-red;
        color: $white;
      }
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      text-align: center;

      li {
        display: inline-block;
        margin: 0 3px 10px;
      }
    }
  }
}

/*** Icons ***/
.magazine--icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: inherit;
  vertical-align: baseline;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;

  &--calendar {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 19'%3E%3Cpath d='M19.333 1.115c.916 0 1.667.69 1.667 1.533v13.027c0 .844-.751 1.533-1.667 1.533H2.667C1.75 17.207 1 16.518 1 15.674V2.648c0-.843.75-1.533 1.667-1.533h16.666zm0-.92H2.667C1.196.195 0 1.295 0 2.648v13.027c0 1.352 1.196 2.452 2.667 2.452h16.666c1.471 0 2.667-1.1 2.667-2.452V2.648C22 1.295 20.804.195 19.333.195z'/%3E%3Cpath d='M21 4.18V2.648c0-.843-.751-1.533-1.667-1.533H2.667C1.75 1.115 1 1.805 1 2.648V4.18l20-.001zM8.918 6.484H6.423V8.78h2.495V6.484zM15.577 6.484h-2.495V8.78h2.495V6.484zM18.907 6.484H16.41V8.78h2.497V6.484zM12.248 6.484H9.752V8.78h2.496V6.484zM8.918 9.545H6.423v2.295h2.495V9.545zM5.59 9.545H3.093v2.295H5.59V9.545zM15.577 9.545h-2.495v2.295h2.495V9.545zM18.907 9.545H16.41v2.295h2.497V9.545zM12.248 9.545H9.752v2.295h2.496V9.545zM8.918 12.607H6.423v2.295h2.495v-2.295zM5.59 12.607H3.093v2.295H5.59v-2.295zM15.577 12.607h-2.495v2.295h2.495v-2.295zM12.248 12.607H9.752v2.295h2.496v-2.295z'/%3E%3C/svg%3E");
  }
}

.blog-detail {
  margin-top: 60px;

  @include media-breakpoint-up(xl) {
    margin-top: 120px;
  }
}