.area--parallax {

    &-media {
        position: relative;
        overflow: hidden;
        @include fluid-property('height', $viewport-width-min, $viewport-width-max, 610px, 750px);
        width: 100%;

        img {
            width: 100vmax;
            z-index: -1;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%,0);
            pointer-events: none;
            object-fit: cover;
            filter: brightness(0.6);
        }

        picture {
            position: absolute;
            height: 100%;
            width: 100%;
        }

        figure {
            height: 100%;
        }
    }

    &-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: $white;

        h2 {
            @include fluid-type($viewport-width-min, $viewport-width-max, 55px, 100px)
        }

        @include media-breakpoint-down(md) {
            left: unset;
            transform: translate(0, -50%);
        }
    }
}