.main-nav {
  position: fixed;
  top: 57px;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 8;
  background-color: $brand-primary;
  opacity: 0;
  transform: scale(0);
  transition: opacity ease 0.3s, transform ease 0.3s;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 40px;
  overflow: scroll;

  @include media-breakpoint-between(md, xl) {
    left: 50%;
  }

  @include media-breakpoint-up(xl) {
    opacity: unset;
    transform: unset;
    bottom: unset;
    padding: 0;
    right: 200px;
    left: 220px;
    grid-area: nav;
    position: unset;
    overflow: unset;
    height: unset;
  }

  &--list {
    @include media-breakpoint-up(xl) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
    }
  }

  &--item {
    margin-bottom: 30px;

    @include media-breakpoint-up(xl) {
      height: 100%;
      display: flex;
      align-items: center;
      margin-right: 60px;
      margin-bottom: 0;

      &:last-child {
        margin-right: 60px;
      }
    }

    label {
      height: 40px;
      width: 40px;
      float: right;
      border: 1px solid $white;
      border-radius: $btn-border-radius;
      display: flex;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-up(xl) {
        display: none;
      }

      i {
        transition: transform 0.3s;
        margin-left: 2px;
      }
    }

    & > a {
      font-size: 20px;
      font-weight: $font-weight-bold;
      line-height: 40px;
      text-transform: uppercase;

      @include media-breakpoint-up(xl) {
        line-height: 30px;
        text-align: center;
      }
    }

    ul {
      max-height: 0;
      visibility: hidden;

      @include media-breakpoint-up(xl) {
        display: none;
        width: 100vw;

        &:before {
          content: "";
          background-color: $brand-lightgrey;
          display: inline-block;
          position: absolute;
          height: 100%;
          width: 100%;
          z-index: -1;
          left: -100%;
        }
      }
    }

    li {
      background-color: $white;
      opacity: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      transition: background-color 0.3s;

      @include media-breakpoint-up(xl) {
        width: fit-content;
        min-width: 360px;
      }

      &:not(:last-child) {
        margin-bottom: 5px;

        @include media-breakpoint-up(xl) {
          margin-bottom: 10px;
        }
      }

      &:first-child {
        @include media-breakpoint-up(xl) {
          margin-top: 60px;
        }
      }

      &:last-child {
        margin-bottom: 30px;

        @include media-breakpoint-up(xl) {
          margin-bottom: 60px;
        }
      }

      &:hover {
        background-color: $brand-primary;

        a {
          color: $white;
        }

        .angle-grey {
          opacity: 1;
          border-color: $white;
        }
      }

      a {
        color: $brand-darkgrey;
        display: inline-block;
        width: 100%;
      }
    }

    &.has-dropdown {

      @include media-breakpoint-up(xl) {
        position: relative;

        &:hover {

          .dot {
            border-radius: 100%;

            &.big {
              height: 20px;
              width: 20px;
              background-color: $white;
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              bottom: -10px;
              left: calc(50% - 5px);
              z-index: 9;
            }

            &.small {
              height: 10px;
              width: 10px;
              background-color: $brand-red;
              display: block;
            }
          }

          ul {
            display: block;
            flex-direction: column;
            position: absolute;
            top: 100%;
            background-color: $brand-lightgrey;
            visibility: visible;
            max-height: 1000px;

            li {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  a {
    color: $white;
  }

  .partner-sites {
    display: flex;
    margin-bottom: 50px;

    @include media-breakpoint-up(xl) {
      position: absolute;
      top: -40px;
      left: 0;
      right: 0;
      height: 40px;
      background-color: $white;
      text-align: end;
      padding-right: 70px;
      display: unset;
      margin-bottom: 0;

      li {
        display: inline-block;
        width: unset;
        margin-top: 8px;

        &:not(:last-child) {
          padding-right: 30px;
        }
      }

      a {
        color: $brand-red;
      }
    }

    a {
      color: $white;
      font-size: 12px;
      font-weight: 600;
    }

    li {
      width: 100%;
      justify-self: center;
      text-align: center;

      &:first-child {
        text-align: start;
      }

      &:last-child {
        text-align: end;
      }

      &:not(:last-child) {
        border-right: 1px dashed $white;
      }
    }
  }
}

[for="toggleMobileNav"] {
  position: absolute;
  top: 21px;
  right: 20px;

  @include media-breakpoint-up(xl) {
    display: none !important;
  }
}

[name="toggleMobileNav"] {
  display: none;

  &:checked {

    & ~ .page-wrap {

      .page-header label[for="toggleMobileNav"] {

        .hamburger--box-inner {
          background-color: transparent;

          &:before {
            top: 0;
            transform: rotate(45deg);
          }

          &:after {
            top: 0;
            transform: rotate(-45deg);
          }
        }
      }

      .page-header .main-nav {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

[name="toggleSubNav"] {
  display: none;

  &:checked {

    & + label {
      background-color: rgba(0, 0, 0, 0.1);
      border-color: transparent;

      i {
        transform: rotate(90deg);
        margin-left: 0;
        margin-top: 3px;
      }
    }

    & ~ ul {
      visibility: visible;
      max-height: 1000px;
      transition: max-height 0.5s;

      li {
        opacity: 1;
        transition: opacity 0.5s;

        &:first-child {
          margin-top: 30px;

          @include media-breakpoint-up(xl) {
            margin-top: 60px;
          }
        }
      }
    }
  }
}

.angle {
  height: 10px;
  width: 10px;
  display: inline-block;
  margin-right: 15px;
  transform: rotate(45deg);

  &.right {
    border-top: 2px solid;
    border-right: 2px solid;
  }

  &.left {
    border-bottom: 2px solid;
    border-left: 2px solid;
  }

  &-grey {
    border-color: $brand-darkgrey;
    opacity: 0.1;
  }
}

.animate-pop-in {
  animation: pop-in .6s cubic-bezier(0, 0.9, 0.3, 1.2) forwards;
  opacity: 0;
}

.microsite {

  .main-nav {
    background-color: var(--micrositeHeaderbarColor);

    @include media-breakpoint-up(xl) {
      &--item ul, &--item ul:before {
        background-color: var(--micrositeNavigationColor) !important;
      }
    }

    &--item {

      a {
        color: var(--micrositeHeaderItemColor);
      }

      ul {

        li {
          background-color: var(--micrositeNavigationItemBackground);
        }

        li:hover {
          background-color: var(--micrositeNavigationItemBackground);

          a {
            color: var(--micrositeHeaderItemColor);
          }

          span {
            border-color: #445572;
          }
        }
      }
    }
  }
}