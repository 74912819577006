.accordion {
    &.active {
        .accordion-content {
            display: block;
        }

        .accordion-header {
            .btn {
                color: $black;
            }
        }
    }

    &-header {
        .btn {
            color: $brand-primary;
        }
    }

    &-content {
        display: none;
    }

    &-body {
        > .area:first-child {
            margin-top: 0;
        }

        > .area:last-child {
            margin-bottom: 0;
        }
    }
}