
// Basic Variables
$font-size-root: 16px;
$line-height-base: 1.6;
$wrapper-max-width: (1800px / $font-size-root) * 1rem;

// Basic Colors
$white:    #fff !default;
$gray: #e0e0e0;
$black:    #000 !default;

$body-bg: $white;
$text-color: $black;

$brand-primary: #81AFAD;
$brand-darkgrey: #445572;
$brand-red: #CC0000;
$brand-lightgrey: #F3F8F9;
$brand-turquoise: #58B9CD;
$brand-green: #00A94F;
$brand-darkblue: #445572;


// Typography
$font-family-base: 'Open Sans', sans-serif;
$font-family-headline: 'Open Sans Condensed', sans-serif;
$font-family-special: 'Summer Loving Regular', serif;
$font-family-quote: 'Sedgwick Ave', serif;
$font-size-base: $font-size-root;
$font-size-small: 12px;
$font-weight-tiny: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: 900;

$h1-font-size:                80px;
$h2-font-size:                65px;
$h3-font-size:                45px;
$h4-font-size:                30px;
$h5-font-size:                25px;
$h6-font-size:                $font-size-base !default;

$font-size-special:           160px;

$h1-min-font-size:            50px;
$h2-min-font-size:            40px;
$h3-min-font-size:            35px;
$h4-min-font-size:            25px;
$h5-min-font-size:            25px;
$h6-min-font-size:            $h6-font-size;

$font-min-size-special:       100px;

$lead-font-size:			  18px;
$lead-min-font-size:		  18px;

$headings-margin-bottom: 1em;
$paragraph-margin-bottom: 1em;

// Fluid Typo Viewport
$viewport-width-min: 768px;
$viewport-width-max: 1200px;

// Containers
$container-padding: 60px;
$container-max-width: 1140px;
$container-max-width--narrow: 960px;

// Grid
$grid-gutter-width: 60px;

// Links
$link-color: $brand-primary;
$link-hover-color: darken($brand-primary, 10%);
$link-decoration: none;
$link-hover-decoration: none;

// Tables
$table-padding-x: 15px;
$table-padding-y: 10px;
$table-border-color: rgba($brand-darkgrey, 0.1);
$table-head-color: $white;
$table-head-bg: $brand-primary;
$table-head-secondary-color: $text-color;
$table-head-secondary-bg: $table-border-color;

// Buttons
$btn-font-family: $font-family-base;
$btn-font-size: $font-size-base;
$btn-font-weight: $font-weight-bold;
$btn-line-height: 1.17;
$btn-padding: 20px 30px;
$btn-padding-sm: 15px 25px;
$btn-border-width: 1px;
$btn-border-radius: 30px;
$btn-disabled-opacity: 0.4;
$btn-primary-bg: $brand-primary;

// Forms
$form-gutter-width: 15px;
$formfield-color: $text-color;
$formfield-bg: $brand-lightgrey;
$formfield-padding: 8px 12px;
$formfield-border-width: 1px;
$formfield-border-radius: 5px;
$formfield-border-color: rgba(68, 85, 114, 0.2);
$formfield-focus-color: $text-color;
$formfield-focus-border-color: rgba($brand-primary, .5);
$formfield-focus-bg: $formfield-bg;

// Breakpoints
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px,
        xxxl: 1600px,
        outer: 1920px,
) !default;

$maxWidthDesktop: map-get($grid-breakpoints, xl);
$maxWidthDesktopPlus: map-get($grid-breakpoints, xxxl);
$outerLimit: map-get($grid-breakpoints, outer);