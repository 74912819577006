.area--icon-teaser {

  article {
    margin-left: 30px;
    padding: 30px 30px 30px 45px;
    position: relative;
    background-color: $white;

    h4 {
      margin-bottom: 10px;
    }

    @include media-breakpoint-up(xl) {
      margin-left: 0;
      margin-top: 55px;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 80px;
      padding-bottom: 60px;
      text-align: center;

      h4 {
        margin-bottom: 30px;
      }
    }

    span {
      height: 60px;
      width: 60px;
      background-color: $brand-primary;
      border-radius: 100%;
      position: absolute;
      left: -30px;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        height: 2.3em;
        width: 2.3em;
      }

      @include media-breakpoint-up(xl) {
        height: 110px;
        width: 110px;
        left: 50%;
        transform: translateX(-50%);
        top: -55px;

        i {
          height: 3.3em;
          width: 3.3em;
        }
      }
    }
  }

  .cols {

    @include media-breakpoint-down(sm) {
      grid-row-gap: 15px;
      grid-colum-gap: 15px;
    }

    &--3 {
      @include media-breakpoint-up(xl) {
        grid-template-columns: repeat(3, 1fr);
      }

      @include media-breakpoint-between(sm, xl) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &--4 {
      @include media-breakpoint-up(xl) {
        grid-template-columns: repeat(4, 1fr);
      }

      @include media-breakpoint-between(sm, xl) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
.col {
  .area--icon-teaser {
    margin: 0;
  }
}

.microsite {
  .area--icon-teaser article span {
    background-color: var(--micrositeAreaPrimaryColor);
  }
}