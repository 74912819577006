.area--text {
    width: 100%;
    border: none;

    th,
    td {
        padding: $table-padding-y $table-padding-x;
        background-color: $brand-lightgrey;

        @include media-breakpoint-down(md){
            padding-left: 1em!important;
            padding-right: 30px!important;
        }

        &:first-child {
            padding-left: $table-padding-x * 2;
        }
    }

    thead {
        tr:first-child th {
            background-color: $table-head-bg;
            color: $table-head-color;
        }

        tr:not(:first-child) th {
            background-color: $table-head-secondary-bg;
            color: $brand-darkgrey;
        }
    }

    td {
        border-bottom: 1px solid $white;

        &:not(:last-child) {
            border-right: 1px solid $white;
        }
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }

    // Spezial Responsive Table
    @include media-breakpoint-down(md) {
        table, thead, tbody, tr, th, td {
            display: block;
        }

        tr:last-child {
            td:last-child {
                border-bottom: 0;

                &:before {
                    border-bottom: 0;
                }
            }
        }

        &.has-thead {
            thead tr:last-child {
                display: none;
            }

            tbody {
                td {
                    display: flex;
                    position: relative;
                    padding: $table-padding-y $table-padding-x $table-padding-y 0;
                    align-items: center;
                    align-content: center;

                    &:before {
                        display: flex;
                        align-items: center;
                        align-content: center;
                        content: attr(data-label);
                        margin-top: -$table-padding-y;
                        margin-bottom: -$table-padding-y;
                        margin-right: $table-padding-x;
                        padding: $table-padding-y $table-padding-x;
                        width: 45%;
                        flex: 0 0 45%;
                        align-self: stretch;

                    }
                }
            }

            &:not(.multiple-thead-rows) {
                tbody td:before {
                    background: $table-head-bg;
                    border-bottom: 1px solid $table-border-color;
                    color: $table-head-color;
                }
            }
        }
    }


    // normale responsive table (horizontal scroll)
    .table-responsive {
        @include media-breakpoint-down(sm) {
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
        }
    }

    @include media-breakpoint-down(md) {

        table, tr, td {
            padding: 0;
        }

        table {
            border: none;
        }

        thead {
            display: none;
        }

        tr {
            float: left;
            width: 100%;
            margin-bottom: 2em;
        }

        td {
            float: left;
            width: 100%;
            padding: 1em;

            &:first-child {
                background-color: $brand-primary !important;
                color: $white !important;
            }
        }

        td::before {
            content: attr(data-label);
            word-wrap: break-word;
            background-color: $brand-lightgrey !important;
            float: left;
            font-weight: bold;
            color: $brand-darkblue !important;
        }

        td:first-child::before {
            background-color: $brand-primary !important;
            color: $white !important;
        }
    }

    .microsite {
        .area--text table thead tr:first-child th, table.contenttable thead tr:first-child th {
            background-color: var(--micrositeAreaPrimaryColor);
        }

        .area--text table td, table.contenttable td {
            background-color: var(--micrositeAreaSecondaryColor);
            }
    }

}