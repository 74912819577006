h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    line-height: 1.2;
    font-family: $font-family-headline;
    font-weight: $font-weight-tiny;
    margin-top: 0;
    margin-bottom: 0;
    word-break: break-all;
    text-transform: uppercase;

    @include hyphens-auto(true);

    a {
        color: inherit;
        text-decoration: none;

        &:hover,
        &:focus {
            color: inherit;
            text-decoration: none;
        }
    }

    small {
        display: block;
        margin-top: 0.25em;
        line-height: 1.15;
        font-size: .6em;
        font-weight: $font-weight-normal;
        word-break: normal;
    }
}

h1, .h1 {
    @include fluid-type($viewport-width-min, $viewport-width-max, $h1-min-font-size, $h1-font-size);
}


h2, .h2 {
    @include fluid-type($viewport-width-min, $viewport-width-max, $h2-min-font-size, $h2-font-size);
}


h3, .h3 {
    @include fluid-type($viewport-width-min, $viewport-width-max, $h3-min-font-size, $h3-font-size);

    small {
        @include media-breakpoint-down(md) {
            font-size: $font-size-base;
        }
    }
}


h4, .h4 {
    @include fluid-type($viewport-width-min, $viewport-width-max, $h4-min-font-size, $h4-font-size);

    small {
        font-size: .8em;

        @include media-breakpoint-down(md) {
            font-size: $font-size-base;
        }
    }
}


h5, .h5 {
    @include fluid-type($viewport-width-min, $viewport-width-max, $h5-min-font-size, $h5-font-size);

    small {
        font-size: 1em;
    }
}


h6, .h6 {
    @include fluid-type($viewport-width-min, $viewport-width-max, $h6-min-font-size, $h6-font-size);

    small {
        font-size: 1em;
    }
}

.special-headline {
    font-family: $font-family-special;
    transform: rotate(-5deg);
    @include fluid-type($viewport-width-min, $viewport-width-max, $font-min-size-special, $font-size-special);
    @include fluid-property('line-height', $viewport-width-min, $viewport-width-max, 60px, 100px);
}


hr {
    margin-top: to-rem(30px);
    margin-bottom: to-rem(30px);
    opacity: 1;
    border: 1px dashed $brand-primary;

    @include media-breakpoint-up(lg) {
        margin-top: to-rem(60px);
        margin-bottom: to-rem(60px);
    }
}

p {
    margin-bottom: $paragraph-margin-bottom;
}

.lead {
    @include fluid-type($viewport-width-min, $viewport-width-max, $lead-min-font-size, $lead-font-size);
    margin-bottom: $paragraph-margin-bottom * 1.2;
    font-weight: 600;
    line-height: 26px;

    strong {
        font-weight: $font-weight-bolder;
    }
}

// text alignment
.text {
    &-left {
        text-align: left !important;
    }

    &-right {
        text-align: right !important;
    }

    &-center {
        text-align: center !important;
    }

    &-justify {
        text-align: justify !important;
    }

    &-red {
        color: $brand-red !important;
    }

    &-small {
        font-size: $font-size-small;
    }
}

.microsite {

    .text-red {
        color: var(--micrositeHeadlineColor) !important;
    }
}