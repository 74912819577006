.teaserslider-header {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: absolute;
  bottom: 90px;
  width: 100%;
  animation: pop-in .6s cubic-bezier(0, 0.9, 0.3, 1.2) forwards;
  animation-delay: 0.6s;
  opacity: 0;
  z-index: 1;

  @include media-breakpoint-down(xl) {
    flex-direction: column;
    align-items: center;
  }

  &--content {
    max-width: 1150px;
    background-color: $white;
  }

  .slider--controls {
    position: absolute;
    bottom: -30px;
    left: 420px;
    height: 60px;
    display: flex;
    z-index: 8;

    .arrow {
      margin: 0 15px;
    }
  }

  &--image {
    float: left;
    width: 270px;
    height: 100%;
    position: relative;

    img {
      position: absolute;
      object-fit: cover;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  &--text {
    float: left;
    padding-left: 60px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    max-width: 560px;
    max-height: 560px;
    //height: 200px;
    overflow: hidden;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;

    @include media-breakpoint-down(xl) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    h5 {
      margin-bottom: 1rem;
      color: $brand-red;
    }

    .btn {
      display: none;
      text-align: center;
    }
  }

  &--button {
    padding: 0 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    height: 100%;
    text-align: center;
  }

  .arrow {
    height: 60px;
    width: 60px;
    border-radius: 100%;
    background-color: $brand-primary;
    border-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      filter: brightness(0) invert(1);
      height: 20px;
      width: 20px;
    }

    &--prev {
      transform: rotate(180deg);
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    }
  }
}

@include media-breakpoint-down(xl) {

  .teaserslider-header {
    position: absolute;
    bottom: -200px;

    &--image {
      display: none;
    }

    &--text {
      padding: 30px 30px 20px 30px;
      border-right: none;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
      height: unset;
      margin: 30px 0;
      background-color: $white;

      h5 {
        text-align: center;
      }

      .btn {
        display: block;
        align-self: center;
        margin-bottom: 25px;
      }
    }

    &--content {
      max-width: 100%;
      background-color: transparent;
    }

    &--button {
      display: none;
    }

    .slider--controls {
      height: 50px;
      right: 50%;
      bottom: 0;
      transform: translateX(50%);
      left: unset;

      .arrow {
        height: 50px;
        width: 50px;
      }
    }

    .splide__slide {
      display: flex;
      justify-content: center;
    }
  }

  .frontpage:not(.microsite) .main-content {
    padding-top: 250px;
  }
}