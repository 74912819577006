.footer--main {
  background-color: $brand-lightgrey;
  padding-top: 160px;

  @include media-breakpoint-down(md) {
    padding-top: 80px;
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 30px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      gap: 0;
    }

    .col {
      flex: 1 0 auto;
      max-width: 330px;

      @include media-breakpoint-down(sm) {
        max-width: unset;
      }
    }
  }

  &-pages{
    display: flex;
    max-width: unset !important;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    .footer--main-links {
      width: 100%;

      &:first-child {
        margin-right: 30px;

        @include media-breakpoint-down(sm) {
          margin-right: 0;
        }
      }
    }
  }

  &-content {
    margin-bottom: 60px;
  }

  &-contact {
    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
      text-align: center;
    }

    h5 {
      margin-bottom: 40px;
    }

    p:nth-child(2) {
      margin-bottom: 0;
    }
  }

  &-links {

    li {
      background-color: $white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      margin-bottom: 10px;
      transition: background-color 0.3s;

      &:hover {
        background-color: $brand-primary;

        a {
          color: $white;
        }

        .angle-grey {
          opacity: 1;
          border-color: $white;
        }
      }

      a {
        width: 100%;
      }
    }
  }

  &-icons {
    margin-top: 65px;

    @include media-breakpoint-down(sm) {
      display: flex;
      justify-content: center;
      margin-bottom: 60px;
      margin-top: 0;

      li {
        margin-bottom: 0;
        &:nth-child(2) {
          margin: 0 15px;
        }
      }
    }

    li {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }

    span {
      height: 40px;
      width: 40px;
      background-color: $brand-red;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
    }

    i {
      height: 25px;
      width: 25px;
    }

    a:not(.footer-icons) {
      margin-left: 15px;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  &-partner {
    text-align: center;
    margin-bottom: 40px;

    &-headline {
      position: relative;
      height: 12px;
      margin-bottom: 40px;

      p {
        margin-bottom: 0;
        background-color: $brand-lightgrey;
        position: absolute;
        font-size: 12px;
        line-height: 12px;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
        width: fit-content;
      }

      span {
        position: absolute;
        height: calc(50% + 1px);
        border-top: 1px dashed $brand-darkgrey;
        width: 100%;
        bottom: 0;
        left: 0;
      }
    }

    li {
      display: inline-block;
      margin-right: 80px;

      &:last-child {
        margin-right: 0;
      }

      @include media-breakpoint-down(xl) {
        margin: 20px;

        &:last-child {
          margin-right: unset;
        }
      }

      a:hover img {
        filter: unset;
        opacity: 1;
      }

      img {
        filter: grayscale(100%) brightness(75%) contrast(2);
        opacity: 0.5;
        transition: opacity 0.3s, filter 0.3s;
      }
    }
  }

  &-copyright {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background-color: $brand-primary;
    padding: 30px;
    color: $white;

    p {
      margin: .5em 0;
      color: $white !important;
    }

    svg {
      margin: .5em 0;
    }

    @include media-breakpoint-down(sm) {

    }

    @include media-breakpoint-up(lg) {
      padding: 30px 60px;
    }
  }

  a,p,h5  {
    color: $brand-darkblue;
  }

  .conecto-copyright {
    background-image: svg-load('../images/layout/svg/conecto.svg');
    background-repeat: no-repeat;
  }
}

.microsite {

  .footer--main-content {
    justify-content: flex-start !important;
  }

  .footer--main-contact {

    p, h5 {
      color: inherit !important;
    }

    @media screen and (min-width: 606px) {
      &:last-child {
        margin-top: 70px;
      }
    }
  }

  .footer--main-links {

    li:hover {
      background-color: $white !important;

      .angle-grey {
        opacity: .1;
        border-color: $brand-darkblue;
      }
    }
  }

  .footer--main-icons {
    margin-top: 0 !important;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      margin-bottom: 0;
    }

    li {
      margin-bottom: 15px;
    }

    li:nth-child(2) {
      margin: 0 0 15px 0 !important;
    }

    a {
      display: block !important;
    }
  }

  .footer--main-copyright {
    display: block;

    a {
      color: $white !important;
    }
  }
}