.breadcrumb {
  position: relative;
  z-index: 1;
  background: $brand-lightgrey;

  &--list {
    text-align: center;
    padding: 30px 30px 60px 30px;
    list-style: none;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;

    li:not(:last-child) {

      &:after {
        content: '>';
        margin-left: 5px;
      }
    }
  }

  a {
    color: $brand-darkgrey;
    opacity: 0.5;
  }

  &--buttons {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 15px;
  }
}

.c-share {
  position: relative;
  width: 60px;
  height: 60px;

  &.icons {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0.1);

    &.international, &.yala, &.share {
      animation: pop-in .6s cubic-bezier(0, 0.9, 0.3, 1.2) forwards;
    }

    &.international {
      border-color: $brand-red;
      animation-delay: 0.3s;

      i {
        height: 100%;
        width: 100%;
      }
    }

    &.yala {
      animation-delay: 0.6s;
    }

    &.share {
      animation-delay: 0.9s;
    }
  }
}

.c-share__input {
  display: none;

  &:checked ~  {
    .c-share__toggler .c-share__icon {
      transition: 0s;
      width: 0;
      height: 0;
      color: $white;

      &::before {
        transform: rotate(-45deg);
      }

      &::after {
        transform: rotate(45deg);
      }
    }

    .c-share_options {
      width: 11.0625em;
      //height: 12.5em;
      height: auto;
      border-radius: 0.3125em;

      a {
        color: $white !important;
        opacity: 1 !important;
      }

      &::before,
      li {
        transition: 0.3s 0.15s;
        opacity: 1;
        transform: translateY(0);
        color: $white;
      }
    }
  }
}

.c-share__toggler,
.c-share_options {
  position: absolute;
  right: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  background-color: $brand-primary;
}

.c-share__toggler {
  cursor: pointer;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;
}

.c-share__icon {
  position: relative;
  left: -0.3125em;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  color: $white;
  background-color: currentColor;
  box-shadow: 0.625em -0.625em, 0.625em 0.625em;
  transition: 0.1s 0.05s;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 1em;
    height: 0.125em;
    background-color: currentColor;
    transition: 0.1s;
  }

  &::before {
    top: 0.1875em;
    left: 0.1875em;
    transform-origin: bottom left;
    transform: rotate(45deg);
  }

  &::after {
    top: -0.125em;
    left: 0.03125em;
    transform-origin: center;
    transform: rotate(-45deg);
  }
}

.c-share_options {
  list-style: none;
  margin: 0;
  padding: 1.0625em 1.25em;
  box-sizing: border-box;
  overflow: hidden;
  transition: 0.2s;
  box-shadow: 0 0.125em 0.125em rgba($black, 0.3);

  &::before {
    content: attr(data-title);
    display: block;
    margin-bottom: 1.25em;
    font-weight: $font-weight-bold;
  }

  li {
    font-size: 0.875em;
    color: $white;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 0.75em;
    }
  }

  &::before,
  li {
    opacity: 0;
    transform: translateY(0.625em);
    transition: 0s;
  }
}

.form .breadcrumb {
  top: 120px;

  @include media-breakpoint-down(xl) {
    top: 60px;
  }
}