.area--accordion {
  
  label {
    cursor: pointer;
  }
  
  section {
    width: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-container {
    width: 100%;
    max-width: 80rem;
    margin: 0 auto;
    padding: 0 1.5rem;
  }

  .accordion-item{
    background-color: transparent;
    border-radius: .4rem;

    hr {
      border: 1px dashed $black;
      margin: 0 !important;
    }
  }

  .accordion-link{
    font-size: 1.6rem;
    color: $black;
    text-decoration: none;
    background-color: transparent;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 20px;

    @include media-breakpoint-down(sm) {
      padding: 1rem 15px;
    }

    h3 {
      font-weight:500;
      font-size: 20px;
      padding-right: 10px;
    }

    i {
      color: $black;
      padding: .5rem;
    }

    ul {
      display:flex;
      align-items: flex-end;
      list-style-type: none;
      margin-left: 25px;

      li {
        font-size: 10px;
        color: $black;
        padding: 0 0 1px 5px;
      }
    }

    div {
      display: flex;
    }
  }

  .accordion--angle {
    height: 30px;
    width: 30px;
    min-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-color: rgba(0, 0, 0, 0.1);
    border-radius: 100%;

    span {
      margin: 0;
      height: 8px;
      width: 8px;
      transition: transform 0.3s;
    }
  }

  .answer {
    max-height: 0;
    overflow: hidden;
    position: relative;
    background-color: transparent;
    transition: max-height 0.6s;
    padding: 0 20px;

    @include media-breakpoint-down(sm) {
      padding: 0 15px;
    }

    p {
      margin-bottom: 0;
    }
  }

  .area--text {
    margin-top: 0 !important;
    margin-bottom: 1rem !important;
  }

  [name="toogleAccordion"]:checked {

    &  ~ .answer {
      max-height: 200rem;
      transition: max-height 3s;
    }

    & ~ .accordion-link .accordion--angle {
      border-color: $black;

      span {
        border-color: $black;
        opacity: 1;
        transform: rotate(135deg);
      }
    }
  }

  [name="toogleAccordion"] {
    display: none;
  }
}