.id-136 {

  .container {
    @include media-breakpoint-down(sm) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .cols--2.ratio--60-40 {
    @include media-breakpoint-down(lg) {
      grid-template-columns: 1fr;
    }
  }
}

.id-147 {
  .main-content {
    padding-bottom: 0 !important;
  }
}

.id-2, .id-98  {
  .area--html img {
    margin-right: 25px;
  }
}

.id-165 {
  .header-media {
    @include media-breakpoint-up(md) {
      height: 85vh !important;
    }
  }
}

@include media-breakpoint-down(md){
  .travel-data-table{
    td{
      &:first-child{
        width: 100% !important;
      }

      &:before{
        display: none;
      }
    }
  }

  .form-line{
    padding-left: 28px!important;
    padding-right: 28px!important;
  }
}
