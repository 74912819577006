.area--disruptor {

  &-media {
    position: relative;
    padding: 60px;
    @include fluid-property('height', $viewport-width-min, $viewport-width-max, 375px, 970px);

    @include media-breakpoint-down(lg) {
      padding-bottom: 0;
    }

    @include media-breakpoint-down(md) {
      padding: 0;
    }

    .area--thumbnail {
      height: 100%;

      img,
      picture,
      video,
      iframe {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  &-text {
    max-width: 555px;
    padding: 60px 0;
    position: absolute;
    bottom: 0;
    left: 160px;
    background-color: $white;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.1);

    @include media-breakpoint-down(lg) {
      position: relative;
      left: unset;
      margin: 0 auto;
      bottom: 0;
    }

    @include media-breakpoint-down(md) {
      padding: 60px 0 80px 0;
    }

    h4 {
      margin-bottom: 30px;
      color: $brand-red;
    }

    .btn {
      margin-top: 30px;
    }

    &.margin-badge {
      padding-top: 140px;

      @include media-breakpoint-up(lg) {
        padding-top: 60px;
      }
    }
  }

  &-badge {
    background-image: svg-load('../images/layout/svg/disruptor-badge.svg');
    background-size: contain;
    height: 270px;
    width: 270px;
    position: absolute;
    bottom: -135px;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include media-breakpoint-up(lg) {
      top: 160px;
      right: 160px;
      left: unset;
      transform: unset;
    }

    &-text {
      transform: rotate(-5deg);
      display: flex;
      align-items: center;
      font-size: 18px;
      text-transform: uppercase;
      text-align: center;
      padding: 10px 25px 20px;
      color: $brand-primary;
      margin-bottom: 0;
    }

    &-arrow {
      background-image: svg-load('../images/layout/svg/disruptor-arrow.svg');
      background-repeat: no-repeat;
      background-size: contain;
      height: 20px;
      width: 100%;
      transform: rotate(-5deg);
      background-position: center;
    }
  }

  .special-headline {
    position: absolute;
    left: 150px;
    top: 130px;
    color: $white;

    @include media-breakpoint-down(lg) {
      left: 0;
      width: 100%;
      text-align: center;
      top: 100px;
    }
  }

  .area--thumbnail--copyright {
    display: block;
    text-align: end;
  }

  .area--thumbnail--caption {
    text-align: end;
  }

  @include media-breakpoint-down(xl) {

    .area--thumbnail {

      &--caption, &--copyright {
        position: relative;
        top: -100%;
        color: $white;
        display: block;
        text-align: start;
      }
    }
  }
}