.area--quote {

  .quote {
    color: $brand-darkblue;
    text-align: center;
  }

  h4 {
    font-family: $font-family-quote;
    @include fluid-property('line-height', $viewport-width-min, $viewport-width-max, 35px, 40px);
    @include fluid-property('margin-bottom', $viewport-width-min, $viewport-width-max, 20px, 30px);

    &:before {
      content: svg-load('../images/layout/svg/quote.svg');
      display: block;
    }
  }

  p {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    display: inline-block;
    position: relative;

    span {
      background-color: $white;
      padding: 0 10px;
    }

    &:after {
      content: '';
      height: 1px;
      background-color: $brand-darkblue;
      left: -30px;
      right: -30px;
      position: absolute;
      top: 50%;
      z-index: -1;
    }
  }
}