.lang-nav {
  padding: 30px 0;

  @include media-breakpoint-up(xl) {
    padding: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-align: center;

    li {
      display: inline-block;
      text-transform: uppercase;
      color: $white;
      padding: 0.5em;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      margin: 0 10px;

      @include media-breakpoint-up(xl) {
        border: none;
        padding: 0;
      }
    }
  }
}

.main-nav {
  .lang-nav {
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
}

.frontpage  {

  .lang-nav {
    @include media-breakpoint-down(xl) {
      padding: 60px 0 30px 0 !important;
    }
  }
}