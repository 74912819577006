/* open-sans-condensed-300 - latin */
@font-face {
	font-family: 'Open Sans Condensed';
	font-style: normal;
	font-weight: 300;
	src: url('../fonts/googlefonts/open-sans-condensed-v15-latin-300.eot'); /* IE9 Compat Modes */
	src: local(''),
	url('../fonts/googlefonts/open-sans-condensed-v15-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/googlefonts/open-sans-condensed-v15-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/googlefonts/open-sans-condensed-v15-latin-300.woff') format('woff'), /* Modern Browsers */
	url('../fonts/googlefonts/open-sans-condensed-v15-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/googlefonts/open-sans-condensed-v15-latin-300.svg#OpenSansCondensed') format('svg'); /* Legacy iOS */
	font-display: swap;
}
/* open-sans-condensed-700 - latin */
@font-face {
	font-family: 'Open Sans Condensed';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/googlefonts/open-sans-condensed-v15-latin-700.eot'); /* IE9 Compat Modes */
	src: local(''),
	url('../fonts/googlefonts/open-sans-condensed-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/googlefonts/open-sans-condensed-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/googlefonts/open-sans-condensed-v15-latin-700.woff') format('woff'), /* Modern Browsers */
	url('../fonts/googlefonts/open-sans-condensed-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/googlefonts/open-sans-condensed-v15-latin-700.svg#OpenSansCondensed') format('svg'); /* Legacy iOS */
	font-display: swap;
}

/* open-sans-regular - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/googlefonts/open-sans-v27-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
	url('../fonts/googlefonts/open-sans-v27-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/googlefonts/open-sans-v27-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/googlefonts/open-sans-v27-latin-regular.woff') format('woff'), /* Modern Browsers */
	url('../fonts/googlefonts/open-sans-v27-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/googlefonts/open-sans-v27-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
	font-display: swap;
}
/* open-sans-700 - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/googlefonts/open-sans-v27-latin-700.eot'); /* IE9 Compat Modes */
	src: local(''),
	url('../fonts/googlefonts/open-sans-v27-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/googlefonts/open-sans-v27-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/googlefonts/open-sans-v27-latin-700.woff') format('woff'), /* Modern Browsers */
	url('../fonts/googlefonts/open-sans-v27-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/googlefonts/open-sans-v27-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
	font-display: swap;
}

/* sedgwick-ave-regular - latin */
@font-face {
	font-family: 'Sedgwick Ave';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/googlefonts/sedgwick-ave-v7-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
	url('../fonts/googlefonts/sedgwick-ave-v7-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../fonts/googlefonts/sedgwick-ave-v7-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	url('../fonts/googlefonts/sedgwick-ave-v7-latin-regular.woff') format('woff'), /* Modern Browsers */
	url('../fonts/googlefonts/sedgwick-ave-v7-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../fonts/googlefonts/sedgwick-ave-v7-latin-regular.svg#SedgwickAve') format('svg'); /* Legacy iOS */
	font-display: swap;
}