.area--teaser {
    position: relative;

    &.slider {
        @include media-breakpoint-down(sm) {
            @include fullWidthViewport;
            left: 50%;
            transform: translateX(-50%);
        }

        .teaser {
            height: 100%;
        }
    }

    .teaser {

        &--inner {
            height: 100%;
            position: relative;
            transition: background-color 0.3s;

            /* &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 30px;
                right: 30px;
                height: 1px;
                border-bottom: 1px dashed $black;

                @include media-breakpoint-down(sm) {
                    display: none;
                }
            } */

            &:after {
                content: '';
                position: absolute;
                height: 100%;
                width: 15px;
                top: 0;
                right: -15px;
                border-right: 1px dashed $black;
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }

            .teaser--text, .teaser--text a:not(.btn) {
                color: $black !important;
            }
            
            &:hover {
                cursor: pointer;
                background-color: $brand-primary;

                .teaser--text, .teaser--text a:not(.btn) {
                    color: $white !important;

                    li {
                        border-color: $white;
                    }
                }

                h4 {
                    color: $white;
                }

                .btn-secondary {
                    background-color: $white !important;
                    color: $brand-primary !important;
                }

                .yala {
                    background-color: $brand-green;

                    i {
                        filter: saturate(0) brightness(0) invert(100%) opacity(1);
                    }
                }
            }
        }

        header {
            @include fluid-property('margin-bottom', $viewport-width-min, $viewport-width-max, 15px, 20px);

            h4 {
                word-break: break-word;
                color: $brand-red;
            }
        }

        &--image {
            position: relative;

            img {
                width: 100%;
                height: 270px;
                max-width: none;
                object-fit: cover;

                @include media-breakpoint-down(sm) {
                    height: 180px;
                }
            }
        }

        &--text {
            @include fluid-property('padding-top', $viewport-width-min, $viewport-width-max, 45px, 50px);
            padding: 0 30px 30px 30px;
            height: calc(100% - 270px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include media-breakpoint-down(sm) {
                box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
                height: calc(100% - 180px);
            }

            p {
                margin-bottom: 0;
            }
        }

        .btn {
            min-width: 0;
            animation: none;
            @include fluid-property('margin-top', $viewport-width-min, $viewport-width-max, 25px, 30px);
            display: block;
            text-align: center;
        }

        &--information {
            @include fluid-property('margin-top', $viewport-width-min, $viewport-width-max, 15px, 30px);

            li {
                display: flex;
                align-items: center;
                padding: 10px 0;
                border-bottom: 1px dashed $black;

                p {
                    flex: 0 0 50%;
                    margin-bottom: 0;

                    &:first-child {
                        padding-right: 10px;
                    }
                }
            }
        }

        &--price {
            position: absolute;
            z-index: 1;
            right: 0;
            top: 30px;
            background-color: $brand-red;
            padding: 10px 20px 10px 10px;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;

            p {
                color: $white;
                margin-bottom: 0;
                font-size: 12px;
                line-height: 16px;

                strong {
                    font-size: 25px;
                }
            }
        }

        &-logos {
            display: flex;
            gap: 20px;
            padding-left: 30px;
            position: absolute;
            bottom: -30px;

            .c-share {
                animation: none;
            }
        }
    }

    .slider--controls {
        position: absolute;
        @include fluid-property('left', 1260px, 1400px, -50px, -130px);
        @include fluid-property('right', 1260px, 1400px, -50px, -130px);
        display: flex;
        justify-content: space-between;
        top: 50%;
        transform: translateY(-50%);

        @include media-breakpoint-down(sm) {
            display: none;
        }

        .arrow {
            height: 60px;
            width: 60px;
            background-color: $brand-primary;
            border: none;
            border-radius: 100%;
            cursor: pointer;

            &--prev {
                transform: scaleX(-1);
            }
        }

        svg {
            filter: saturate(0) brightness(0) invert(100%) opacity(1);
            height: 20px;
            width: 20px;
        }
    }

    .splide__slide {
        @include media-breakpoint-down(sm) {
            padding-bottom: 30px;
        }
    }

    .splide__pagination {

        li {
            margin: 0 20px;

            button {
                height: 10px;
                width: 10px;
                border-radius: 100%;
                border-color: transparent;

                &.is-active {
                    height: 15px;
                    width: 15px;
                    background-color: $black;
                }
            }
        }
    }

    &:not(.slider) {
        overflow: hidden;
    }
}

.teaser-header {
    position: absolute;
    top: 160px;
    width: 100%;
    z-index: 1;

    @include media-breakpoint-down(xl) {
        top: 90px;
    }

    @include media-breakpoint-down(lg) {
        display: none;
    }

    .area--teaser {
        max-width: $container-max-width;
        margin: 0 auto;
        padding-right: $container-padding;
    }

    .teaser {
        max-width: 360px;
        background-color: $white;
        margin-right: 0;
        margin-left: auto;

        @include media-breakpoint-down(xl) {
            margin: 0 auto;
        }

        &--inner {

            &:before, &:after {
                content: none;
            }
        }

        &--image {
            display: none;
        }
    }
}

.area--teaser-container {

    .area--teaser {
        max-width: $container-max-width;
        margin: 0 auto;
        padding-right: $container-padding;

    }

    .teaser {
        width: 360px;
        background-color: $white;
        margin-right: 0;
        margin-left: auto;

        @include media-breakpoint-down(xl) {
            margin: 0 auto;
        }

        &--inner {

            &:before, &:after {
                content: none;
            }
        }

        &--image {
            display: none;
        }
    }
}

.microsite {

    .area--teaser {

        .teaser-inner:hover, .slider--controls .arrow {
            background-color: var(--micrositeAreaPrimaryColor);
        }
    }
}