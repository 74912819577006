.embed-responsive {
    position: relative;
    width: 100%;
    height: 0;

    &-1by1 {
        padding-bottom: 100%;
    }

    &-3by2 {
        padding-bottom: 66.66666%;
    }

    &-16by9 {
        padding-bottom: 56.25%;
    }

    > * {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    > iframe {
        border: none;
    }
}

.plant {
    position: absolute;
    top: -60px;
    width: 260px;
    height: 230px;
    background-repeat: no-repeat;
    background-size: contain;

    @include media-breakpoint-down(lg) {
        width: 180px;
        height: 150px;
    }

    @include media-breakpoint-down(sm) {
        width: 110px;
        height: 80px;
        top: -20px;
    }

    &-left {
        background-image: svg-load('../images/layout/svg/container-plant-left.svg');
        background-position: left;
        left: 0;
    }

    &-right {
        background-image: svg-load('../images/layout/svg/container-plant-right.svg');
        background-position: right;
        right: 0 ;
    }
}

.block{
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    font-size: 16px;
}
.block h2{
    position: relative;
    display: block;
    text-align: center;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 10vw;
    color: white;
    font-weight: 400;
}
.img-parallax {
    width: 100vmax;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,0);
    pointer-events: none
}

html, body{
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}
.block{
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    font-size: 16px;
}
.block h2{
    position: relative;
    display: block;
    text-align: center;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 10vw;
    color: white;
    font-weight: 400;
}
.img-parallax {
    width: 100vmax;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,0);
    pointer-events: none
}