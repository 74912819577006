.page-header {
  position: relative;

  .social-media, .main-nav--toggle {
    z-index: 10;
  }

  &-bar {
    position: fixed;
    height: 60px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9;
    background-color: $brand-primary;

    @include media-breakpoint-up(xl) {
      height: 80px;
      top: 40px;
      display: grid;
      grid-template-areas: 'logo nav meta';
      grid-template-columns: 1fr 4fr 1fr;

      &--meta {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: $brand-primary;
      }
    }

    &-logo {
      height: 100%;
      position: relative;
      z-index: 9;

      @include media-breakpoint-up(xl) {
        grid-area: logo;
      }

      .logo {
        position: absolute;
        @include fluid-property('height', $viewport-width-min, $viewport-width-max, 75px, 135px);
        @include fluid-property('min-width', $viewport-width-min, $viewport-width-max, 180px, 350px);

        @include media-breakpoint-down(md) {
          height: 60px;
          background-position: center;
        }

        @include media-breakpoint-down(xl) {
          width: unset;
        }

        @include media-breakpoint-between(xl, xxxl) {
          min-width: 100% !important;
          height: 100% !important;
          top: 50%;
        }
      }
    }
  }

  &--icon {
    position: absolute;
    right: 0;
    @include fluid-property('bottom', $viewport-width-min, $viewport-width-max, 30px, 60px);
  }

  .hamburger--box, [for="toggleMobileNav"] {
    height: 19px;
    width: 30px;
    display: block;
  }

  .hamburger--box {
    position: relative;
    @include media-breakpoint-up(xl) {
      display: none;
    }

    &-inner {
      background-color: $white;
      display: block;
      position: absolute;
      top: 8px;
      width: 100%;
      height: 3px;

      &::before, &::after {
        content: '';
        width: 100%;
        height: 3px;
        background-color: $white;
        display: block;
        position: absolute;
        transition: transform 0.3s;
      }

      &::before {
        top: -8px;
      }

      &::after {
        bottom: -8px;
      }
    }
  }

  .social-media {
    position: absolute;
    left: 20px;
    top: 17px;
    display: flex;

    @include media-breakpoint-up(xl) {
      position: unset;
      grid-area: meta;
      border-left: 1px dashed $white;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding-left: 40px;
      row-gap: 5px;
      width: 100%;
    }

    @include media-breakpoint-down(md) {
      .icon--instagram {
        height: 1.5em;
        width: 1.5em;
      }
    }

    .lang-nav {
      display: none;

      @include media-breakpoint-up(xl) {
        display: block;
      }
    }

    & > li:first-child {
      margin-right: 10px;
    }

    & li {
      display: inline-block;

      @include media-breakpoint-up(xl) {
        display: inline-block;
        margin: 0 20px;

        a {
          color: $white;
          text-transform: uppercase;
        }
      }
    }
  }
}

.frontpage {

  .page-header--icon {
    @include fluid-property('bottom', $viewport-width-min, $viewport-width-max, 160px, 320px);
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.3);
    }
  }
}

//.microsite:not(.show-camps-logo) {
//
//  .page-header-bar {
//
//    @include media-breakpoint-up(xl) {
//      grid-template-columns: 2fr 4fr 1fr;
//    }
//
//    &--meta {
//      display: none;
//    }
//  }
//}

.microsite {

  .page-header-bar {
    background-color: var(--micrositeHeaderbarColor);
    grid-template-columns: 2fr 4fr .5fr;

    &--meta {
      display: none;
    }

    &-logo {
      display: grid;
      grid-template-columns: repeat(2, 1fr);


      @include media-breakpoint-down(xl) {
        margin-right: 60px;
      }

      .logo-wrap {
        width: 100%;
        height: 100%;
        position: relative;

        a {
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .logo {
        min-width: unset;
        width: 120%;
        height: 150%;
        transition: height .3s, width .3s;
        background-size: contain;

        @include media-breakpoint-down(xl) {
          left: unset;
        }

        @include media-breakpoint-down(xxl) {
          width: 100%;
          height: 100%;
        }
      }

      .partner-logo {
        position: absolute;
        width: 120%;
        height: 120%;
        object-fit: contain;
        transition: height .3s, width .3s;

        @include media-breakpoint-down(xxxl) {
          width: 100%;
          height: 90%;
        }
      }
    }
  }
}

.scroll-direction--down .microsite {

  .page-header-bar {

    &-logo {

      .logo {
        min-width: unset;
        width: 100%;
        height: 100%;
      }

      .partner-logo {
        position: absolute;
        width: 100%;
        height: 90%;
      }
    }
  }
}

.disturber {
  display: block;
  width: 130px;
  height: 130px;
  position: absolute;
  right: 30px;
  top: 30px;
  @media screen and (min-width: 1550px) and (max-width: 1700px) {

    left: unset;
    right: 50px;
    width: 130px;
    height: 130px;
    top: 40px;

    img {
      width: 100%;
      height: auto;
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1550px) {
    right: 225px;
    left: unset;
    top: 20px;
  }

  @media screen and (min-width: 1700px) {
    position: fixed;
    right: unset;
    left: 410px;
    top: 60px;
    z-index: 1000;
  }
}
