.btn {
    display: inline-block;
    padding: $btn-padding;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    font-size: $btn-font-size;
    line-height: $btn-line-height;
    color: $white;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: $btn-border-width solid transparent;
    border-radius: $btn-border-radius;
    box-shadow: none;
    transition: background-color .3s, color .3s;
    text-transform: uppercase;

    &:hover {
        color: $text-color;
        text-decoration: none;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    }

    &:focus {
        outline: 0;
        box-shadow: none;
    }

    &:active,
    &.active {

    }

    &:disabled,
    &.disabled {
        pointer-events: none;
        opacity: $btn-disabled-opacity;
    }

    &-block {
        display: block;
    }

    // Primary Button
    &-primary {
        @include custom-button-variant($brand-red, $brand-red, $white, $white, $white, $brand-red);
        animation: jittery 6s infinite;
    }

    // Secondary Button
    &-secondary {
        @include custom-button-variant($brand-primary, $brand-primary, $white, $white, $white, $brand-primary);
        transition: transform 0.3s;

        &:hover {
            transform: translateY(-6px);
        }
    }

    // Outline Button
    &-outline {
        @include custom-button-variant(transparent, $black, $black, $white, $white, $black);
        //@include btn-border-drawing($black, $brand-primary, 4px, bottom, right);
        border-width: 2px;
    }

    // Large Button
    &-lg {
        font-size: $btn-font-size * 1.2;
    }

    // Small Button
    &-sm {
        font-size: $btn-font-size * 0.9;
        padding: $btn-padding-sm;
    }
}

.microsite {

    .btn {
        background-color: var(--micrositeAreaPrimaryColor) !important;
        border-color: var(--micrositeAreaPrimaryColor) !important;
    }
}