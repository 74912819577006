.header-media {
    position: relative;
    min-height: 250px;
    overflow: hidden;
    height: 650px;
    margin-top: 120px;

    @include media-breakpoint-down(xl) {
        margin-top: 60px;
        height: 650px;
    }

    @include media-breakpoint-down(md) {
        height: 250px;
    }

    > img,
    > picture,
    > picture > img,
    > video,
    > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //animation: fade-slide-down 2s cubic-bezier(0, 0.5, 0, 1) forwards, rotate-up .5s .5s cubic-bezier(0, 0.5, 0, 1) forwards;
        //opacity: 0;
    }

    > img,
    > picture > img,
    > video {
        object-fit: cover;
    }
}

.frontpage .header-media {
    height: calc(100vh - 120px);

    @include media-breakpoint-down(xl) {
        margin-top: 60px;
        height: calc(100vh - 60px);
    }

    @include media-breakpoint-down(md) {
        height: 400px;
    }
}


