@import "mixins/breakpoints";
@import "mixins/icons";
@import "mixins/fluid";

@mixin reset-list {
    &, & li {
        list-style: none;
        padding: 0;
        margin: 0;
    }
}

@mixin hover-transition($value: all, $duration: .23s, $easing: ease-in-out) {
    transition: $value $duration $easing;
}

@mixin hyphens-auto($break-word: false) {
    hyphens: auto;

    @if $break-word {
        word-break: break-all;

        @supports (hyphens: auto) {
            word-break: normal;
        }
    }

    @include media-breakpoint-up(lg) {
        word-break: normal;
    }

}

@mixin custom-button-variant($background, $border, $color, $background-hover, $border-hover, $color-hover) {
    color: $color !important;
    background-color: $background !important;
    border-color: $border !important;

    &:hover {
        color: $color-hover;
        background-color: $background-hover;
        border-color: $border-hover;
    }
}

@mixin fullWidthViewport() {
    width: 100vw;
    width: calc(100vw - var(--scrollbarWidth));
}

@mixin aspect-ratio($arglist... /*$ratio or $width, $height*/){
    $map : keywords($arglist);
    $height: map-get($map, height) or nth-value($arglist, 2);
    $width:  map-get($map, width)  or nth-value($arglist, 1);
    $ratio:  map-get($map, ratio)  or if($width and $height, $width/$height, nth-value($arglist, 1)) or 1;
    $padding: 1/$ratio * 100%;
    &::before, &::after { content: ''; display: table; clear: both; width:1px; margin-left:-1px; }
    &::before { float: left; padding-bottom: $padding; }
}

$scroll-animation-duration: 1s !default;
$scroll-animation-easing: ease-in-out !default;
$scroll-animation-delay: 0s !default;

@mixin scroll-transition($value: all, $duration: $scroll-animation-duration, $easing: $scroll-animation-easing, $delay: $scroll-animation-delay) {
    transition: $value $duration $easing $delay;
}

@mixin scroll-animate() {
    .js &.scroll-animate {
        @content;
    }
}
@mixin scroll-animated() {
    .js &.scroll-animated {
        @content;
    }
}

@mixin pseudo($display: block, $pos: absolute, $content: ''){
    content: $content;
    display: $display;
    position: $pos;
}

@mixin responsive-ratio($x,$y, $pseudo: false) {
    $padding: unquote( ( $y / $x ) * 100 + '%' );
    @if $pseudo {
        &:before {
            @include pseudo($pos: relative);
            width: 100%;
            padding-top: $padding;
        }
    } @else {
        padding-top: $padding;
    }
}

@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false){
    @include pseudo($pos: $position);
    width: 0;
    height: 0;
    @if $round {
        border-radius: 3px;
    }
    @if $direction == down {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-top: $size solid $color;
        margin-top: 0 - round( $size / 2.5 );
    } @else if $direction == up {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-bottom: $size solid $color;
        margin-bottom: 0 - round( $size / 2.5 );
    } @else if $direction == right {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
        margin-right: -$size;
    } @else if  $direction == left {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-right: $size solid $color;
        margin-left: -$size;
    }
}

@mixin css-arrow($color, $direction, $size: 20px, $borderWidth: 4px) {
    display: inline-block;
    width: $size;
    height: $size;
    border-width: $borderWidth $borderWidth 0 0;
    border-style: solid;
    border-color: $color;
    @if $direction == left {
        transform: rotate(-135deg);
    }
    @if $direction == right {
        transform: rotate(45deg);
    }
    @if $direction == up {
        transform: rotate(-45deg);
    }
    @if $direction == down {
        transform: rotate(135deg);
    }
}


@mixin hardware($backface: true, $perspective: 1000) {
    @if $backface {
        backface-visibility: hidden;
    }
    perspective: $perspective;
}

@mixin skew-background-transition($initial, $hover, $inverted: false) {
    background: linear-gradient(
                90deg,
                $hover 0%,
                $hover 50%,
                $initial 50%,
                $initial 100%
    );
    background-repeat: no-repeat;
    background-size: 200% 100%;

    background-position: right bottom;
    @if $inverted {
        background-position: left bottom;
    }
    transition: background-position 0.25s ease-out;

    &:hover {
        background-position: left bottom;
        @if $inverted {
            background-position: right bottom;
        }
    }
}

/* Container Mimicry */
@mixin container() {
    max-width: $maxWidthDesktop;
    padding-left: $containerMarginDesktop;
    padding-right: $containerMarginDesktop;
    margin-left: auto;
    margin-right: auto;
}

/* Outline Button */
@mixin btn-border-drawing($color: #ccc, $hover: black, $width: 2px, $vertical: top, $horizontal: left, $duration: 0.25s) {
    box-shadow: inset 0 0 0 $width $color;
    color: $color;
    transition: color $duration $duration/3;
    position: relative;
    border-radius: $btn-border-radius;

    &::before,
    &::after {
        border: 0 solid transparent;
        box-sizing: border-box;
        content: '';
        pointer-events: none;
        position: absolute;
        width: 0; height: 0;
        border-radius: $btn-border-radius;

        #{$vertical}: 0;
        #{$horizontal}: 0;
    }

    &::before {
        $h-side: if($horizontal == 'left', 'right', 'left');

        border-#{$vertical}-width: $width;
        border-#{$h-side}-width: $width;
    }

    &::after {
        $v-side: if($vertical == 'top', 'bottom', 'top');

        border-#{$v-side}-width: $width;
        border-#{$horizontal}-width: $width;
    }

    &:hover {
        color: $hover;

        &::before,
        &::after {
            border-color: $hover;
            transition: border-color 0s, width $duration, height $duration;
            width: 100%;
            height: 100%;
            border-radius: $btn-border-radius;
        }

        &::before { transition-delay: 0s, 0s, $duration; }

        &::after { transition-delay: 0s, $duration, 0s; }
    }
}