.icon,
.inline-icon {
    display: inline-block;
    vertical-align: middle;
    width: 1.3em;
    height: 1.3em;
    line-height: 1.3em;
}

.icon {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    &--color--white {
        filter: saturate(0) brightness(0) invert(100%) opacity(1);
    }
}

@include generateIconSet($icon-list);
@include generateIconSet($icon-list-teaser);

// Color Variants sollten besser manuell für tatsächlich benötigte Icons erstellt werden,
// damit die data.css nicht zu groß wird
//@include generateIconSetColorVariants($icon-list, $white, "white");