html {
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
    width: 100%;

    @include media-breakpoint-down(md) {
        .offcanvas-nav--visible & {
            overflow-y: hidden;
        }
    }
}

::selection {
    background: $brand-primary;
    color: $white;
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.area--text {
    ul {
        li {
            position: relative;
            padding-left: 30px;

            &:before {
                content: svg-load('../images/layout/svg/li.svg');
                position: absolute;
                left: 0;
            }
        }
    }
}

$layer-banner--bg-color: #B15B19FF;
$layer-banner--txt-color: #000000;
$layer-banner--closeButton-bg: #000000;
$layer-banner--border-radius: 20px;

.layer-banner {
    max-width: calc(100vw - 60px);
    max-height: calc(100vh - 60px);
    width: 640px;
    position: fixed;
    top: 50%;
    left: 50%;
    background-color: $layer-banner--bg-color;
    z-index: 9;
    padding: 50px;
    opacity: 0;
    display: none;
    transform: translate(-50%, -40%);
    transition: opacity 0.3s, transform 0.3s;
    border-radius: $layer-banner--border-radius;
    overflow: hidden;

    &.is-visible {
        opacity: 1;
        transform: translate(-50%, -50%);
        display: block;
    }

    &--media, img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &--content {
        position: relative;
        z-index: 1;
        color: $layer-banner--txt-color;
        word-break: break-word;
        text-align: center;

        h1 {
            font-weight: 700;
            font-size: 2em;
            text-transform: uppercase;
        }

        p {
            font-weight: 700;
            font-size: 1.5em;
            margin-bottom: 0;
        }
    }

    #close-banner {
        background: none;
        border: none;
        position: absolute;
        right: 20px;
        top: 20px;
        padding: 0;
        cursor: pointer;
        z-index: 1;

        i {
            height: 20px;
            width: 20px;
            display: block;

            &:before, &:after {
                content: '';
                height: 3px;
                width: 100%;
                background-color: $layer-banner--closeButton-bg;
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
            }

            &:before {
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &:after {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }
    }

    .text-black {
        color: #000000;
    }

    .text-white {
        color: #ffffff;
    }

    @media (max-width: 575px) {
        padding: 40px 30px;
    }
}

.brauunion {
    height: 80px;
    background-color: rgb(235, 210, 29);
    width: 100%;
}