.area--content-container {
    @include fluid-property('padding-top', $viewport-width-min, $viewport-width-max, 60px, 100px);
    @include fluid-property('padding-bottom', $viewport-width-min, $viewport-width-max, 60px, 100px);
    background: $brand-lightgrey;
    color: $text-color;
    position: relative;

    h2 {
        &.margin-top {
            @include media-breakpoint-up(md) {
                margin-top: 50px;
            }
        }
    }
}

.microsite {
    .area--content-container {
        background: var(--micrositeAreaSecondaryColor);
    }
}