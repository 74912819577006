.area--textmedia {
    padding: 60px 0;
    @include media-breakpoint-down(md) {
        padding: 30px 0;
    }

    .area--thumbnail {
        margin: 0;
        z-index: 2;
        position: relative;
        min-height: 300px;

        img {
            position: absolute;
            height: 100%;
            object-fit: cover;
            min-height: 300px;
            max-height: 540px;
        }

        &--copyright, &--caption {
            display: block;
            position: relative;
            bottom: -100%;
        }
    }

    .area--text {
        position: relative;
        z-index: 1;

        @include media-breakpoint-down(md) {
            margin: 0 15px;
        }

        &--inner {
            padding-top: 30px;

            @include media-breakpoint-up(md) {
                padding-top: 0;
                padding-right: 60px;
            }

            h2 {
                margin-bottom: 30px;
                word-break: break-word;
            }

            .btn {
                margin-top: 30px;
            }

            > *:first-child {
                margin-top: 0;
            }

            > *:last-child {
                margin-bottom: 0;
            }
        }

        &:before {
            content: '';
            position: absolute;
            left: -30px;
            right: -30px;
            top: -50px;
            bottom: -30px;
            border: 1px dotted $black;
            z-index: -1;

            @include media-breakpoint-up(md) {
                right: 0;
                top: -60px;
                bottom: -60px;
                left: -140px;
            }
        }

        &.grass {

            p, li, h3 {
                color: $white !important;
            }

            &:before {
                background-image: url('../images/layout/jpg/img_gras_ohne.jpg');
            }
        }
    }

    // volle breite
    &.full-width {
        .area--text {
            &--inner {
                padding: 30px;

                @include media-breakpoint-up(xl) {
                    padding: 12% 15%;
                }
            }
        }

        .area--thumbnail {
            position: relative;
            margin-bottom: 0;
            overflow: hidden;

            @include media-breakpoint-up(md) {
                width: 100%;
                height: 100%;

                &:before {
                    display: block;
                    content: '';
                    width: 100%;
                    height: 0;
                    padding-bottom: 66.6666%;
                }

                img, video {
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;

                    width: 100%;
                    max-width: none;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

    }

    // farbig hinterlegt
    &.is-highlighted {
        position: relative;

        @include fullWidthViewport;
        left: 50%;
        transform: translateX(-50%);
        background: $brand-primary;

        > .cols {
            > .col {
                &.area--text {
                    @include media-breakpoint-only(md) {
                        padding-left: 30px;
                    }

                    @include media-breakpoint-down(sm) {
                        padding-left: 0;
                    }
                }

                &.thumbnail--right {
                    &.area--text {
                        padding: 0 150px 0 0;

                        @include media-breakpoint-only(md) {
                            padding-right: 30px;
                        }

                        @include media-breakpoint-down(sm) {
                            padding-right: 0;
                        }
                    }
                }
            }
        }

        .area--text {
            color: $white;

            &--inner {
                padding: 30px;

                @include media-breakpoint-up(xl) {
                    padding: 12% 15%;
                }
            }

            a:not(.btn) {
                color: $white;

                &:hover, &:focus {
                    color: rgba(255, 255, 255, .8);
                }
            }

            .btn-primary {
                &, &:before {
                    border-color: $white;
                }
            }

            .thumbnail--right & {

            }
        }
    }

    &.text-center-vertically {
        .area--text {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
        }
    }

    .thumbnail--right {
        [class*="col"]:first-child {
            order: 2;
        }

        [class*="col"]:last-child {
            order: 1;
        }

        .area--text {

            &--inner {
                @include media-breakpoint-up(md) {
                    padding-left: 60px;
                    padding-right: 0;
                }
            }

            &:before {
                bottom: -50px;
                top: 0;

                @include media-breakpoint-up(md) {
                    right: -140px;
                    top: -60px;
                    bottom: -60px;
                    left: 0;
                }
            }
        }
    }

    // Spezialformatierung für cols
    > .cols--6 {
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 0;

        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(6, 1fr);
        }

        @include media-breakpoint-down(md) {
            > [class*="col-"] {
                grid-column: auto / auto;
            }
        }
    }

    .cols {
        @include media-breakpoint-up(xl) {
            grid-column-gap: 80px;
        }

        &--2 {
            @include media-breakpoint-down(md) {
                grid-template-columns: 1fr;
            }
        }
    }
}