.infobox {
    @include fluid-property('padding', $viewport-width-min, $viewport-width-max, 20px, 30px);
    border: 3px solid $brand-turquoise;
    border-radius: 5px;
    position: relative;

    &-logo {
        position: absolute;
        right: 30px;
        top: -44px;

        @include media-breakpoint-down(md) {
            right: 50%;
            transform: translateX(50%);
        }
    }

    &-infografik {
        max-height: 165px;

        @include media-breakpoint-down(md) {
            display: block;
            margin-right: auto;
            margin-left: auto;
            margin-top: 20px;
            margin-bottom: 30px;
        }
    }

    &-inner {
        @include media-breakpoint-up(md) {
            display: flex;

            img {
                margin-right: 30px;
            }
        }
    }

    h4 {
        color: $brand-turquoise;
        margin-bottom: 1rem;

        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }

    > *:last-child {
        margin-bottom: 0;
    }

    hr {
        border: 1px dashed $black;
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    &-primary {
        padding-top: 55px;
        border-style: solid;
        border-color: $brand-green;
        margin-top: 45px !important;

         h4 {
             color: $brand-green;
             margin-top: 1rem;
         }
    }
}

.microsite {

    .infobox {
        border-color: var(--micrositeInfoboxColor);
    }
}