/*** IMPORT CONFIG ***/
/** Icon path is only for Pimcore Icon Utility
$icon-path: /static/images/icons/
*/
/** Icon path is only for Pimcore Icon Utility
$icon-path: /static/images/icons/
*/
/*** IMPORT VENDOR ***/
/*!
 * baguetteBox.js
 * @author  feimosi
 * @version %%INJECT_VERSION%%
 * @url https://github.com/feimosi/baguetteBox.js
 */
#baguetteBox-overlay {
  display: none;
  opacity: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background-color: #222;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity .5s ease; }
  #baguetteBox-overlay.visible {
    opacity: 1; }
  #baguetteBox-overlay .full-image {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center; }
    #baguetteBox-overlay .full-image figure {
      display: inline;
      margin: 0;
      height: 100%; }
    #baguetteBox-overlay .full-image img {
      display: inline-block;
      width: auto;
      height: auto;
      max-height: 100%;
      max-width: 100%;
      vertical-align: middle;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.6); }
    #baguetteBox-overlay .full-image figcaption {
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      line-height: 1.8;
      white-space: normal;
      color: #ccc;
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.6);
      font-family: sans-serif; }
    #baguetteBox-overlay .full-image:before {
      content: "";
      display: inline-block;
      height: 50%;
      width: 1px;
      margin-right: -1px; }

#baguetteBox-slider {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  transition: left .4s ease, transform .4s ease; }
  #baguetteBox-slider.bounce-from-right {
    animation: bounceFromRight .4s ease-out; }
  #baguetteBox-slider.bounce-from-left {
    animation: bounceFromLeft .4s ease-out; }

@keyframes bounceFromRight {
  0% {
    margin-left: 0; }
  50% {
    margin-left: -30px; }
  100% {
    margin-left: 0; } }

@keyframes bounceFromLeft {
  0% {
    margin-left: 0; }
  50% {
    margin-left: 30px; }
  100% {
    margin-left: 0; } }

.baguetteBox-button#next-button, .baguetteBox-button#previous-button {
  top: 50%;
  top: calc(50% - 30px);
  width: 44px;
  height: 60px; }

.baguetteBox-button {
  position: absolute;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 15%;
  background-color: #323232;
  background-color: rgba(50, 50, 50, 0.5);
  color: #ddd;
  font: 1.6em sans-serif;
  transition: background-color .4s ease; }
  .baguetteBox-button:focus, .baguetteBox-button:hover {
    background-color: rgba(50, 50, 50, 0.9); }
  .baguetteBox-button#next-button {
    right: 2%; }
  .baguetteBox-button#previous-button {
    left: 2%; }
  .baguetteBox-button#close-button {
    top: 20px;
    right: 2%;
    right: calc(2% + 6px);
    width: 30px;
    height: 30px; }
  .baguetteBox-button svg {
    position: absolute;
    left: 0;
    top: 0; }

/*
    Preloader
    Borrowed from http://tobiasahlin.com/spinkit/
*/
.baguetteBox-spinner {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px; }

.baguetteBox-double-bounce1,
.baguetteBox-double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: .6;
  position: absolute;
  top: 0;
  left: 0;
  animation: bounce 2s infinite ease-in-out; }

.baguetteBox-double-bounce2 {
  animation-delay: -1s; }

@keyframes bounce {
  0%, 100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }

@keyframes splide-loading {
  0% {
    transform: rotate(0); }
  to {
    transform: rotate(1turn); } }

.splide__container {
  position: relative;
  box-sizing: border-box; }

.splide__list {
  margin: 0 !important;
  padding: 0 !important;
  width: -webkit-max-content;
  width: max-content;
  will-change: transform; }

.splide.is-active .splide__list {
  display: flex; }

.splide__pagination {
  display: inline-flex;
  align-items: center;
  width: 95%;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0; }

.splide__pagination li {
  list-style-type: none;
  display: inline-block;
  line-height: 1;
  margin: 0; }

.splide {
  visibility: hidden; }

.splide, .splide__slide {
  position: relative;
  outline: none; }

.splide__slide {
  box-sizing: border-box;
  list-style-type: none !important;
  margin: 0;
  flex-shrink: 0; }

.splide__slide img {
  vertical-align: bottom; }

.splide__slider {
  position: relative; }

.splide__spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #999;
  border-left-color: transparent;
  animation: splide-loading 1s linear infinite; }

.splide__track {
  position: relative;
  z-index: 0;
  overflow: hidden; }

.splide--draggable > .splide__track > .splide__list > .splide__slide {
  -webkit-user-select: none;
  user-select: none; }

.splide--fade > .splide__track > .splide__list {
  display: block; }

.splide--fade > .splide__track > .splide__list > .splide__slide {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0; }

.splide--fade > .splide__track > .splide__list > .splide__slide.is-active {
  position: relative;
  z-index: 1;
  opacity: 1; }

.splide--rtl {
  direction: rtl; }

.splide--ttb > .splide__track > .splide__list {
  display: block; }

.splide--ttb > .splide__pagination {
  width: auto; }

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08); }

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible; }

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999; }

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px; }

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px); }

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block; }

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6; }

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0; }

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6; }

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto; }

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px; }

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px; }

.flatpickr-calendar.arrowCenter:before,
.flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%; }

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px; }

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px; }

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%; }

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6; }

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff; }

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%; }

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6; }

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff; }

.flatpickr-calendar:focus {
  outline: 0; }

.flatpickr-wrapper {
  position: relative;
  display: inline-block; }

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9); }

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none; }

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative; }

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
      /*rtl:begin:ignore*/
  /*
      */
  left: 0;
  /*
      /*rtl:end:ignore*/
  /*
      */ }

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
      /*rtl:begin:ignore*/
  /*
      */
  right: 0;
  /*
      /*rtl:end:ignore*/
  /*
      */ }

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9; }

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747; }

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px; }

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit; }

.numInputWrapper {
  position: relative;
  height: auto; }

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block; }

.numInputWrapper input {
  width: 100%; }

.numInputWrapper input::-ms-clear {
  display: none; }

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none; }

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1); }

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2); }

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute; }

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0; }

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%; }

.numInputWrapper span.arrowDown {
  top: 50%; }

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%; }

.numInputWrapper span svg {
  width: inherit;
  height: auto; }

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5); }

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05); }

.numInputWrapper:hover span {
  opacity: 1; }

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px); }

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0; }

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05); }

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block; }

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield; }

.flatpickr-current-month input.cur-year:focus {
  outline: 0; }

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none; }

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto; }

.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none; }

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05); }

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0; }

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px; }

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder; }

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0; }

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px; }

.flatpickr-days:focus {
  outline: 0; }

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1; }

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #e6e6e6;
  box-shadow: -1px 0 0 #e6e6e6; }

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center; }

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6; }

.flatpickr-day.today {
  border-color: #959ea9; }

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff; }

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7; }

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px; }

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0; }

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #569ff7;
  box-shadow: -10px 0 0 #569ff7; }

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px; }

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6; }

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default; }

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1); }

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7; }

.flatpickr-day.hidden {
  visibility: hidden; }

.rangeMode .flatpickr-day {
  margin-top: 1px; }

.flatpickr-weekwrapper {
  float: left; }

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #e6e6e6;
  box-shadow: 1px 0 0 #e6e6e6; }

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px; }

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none; }

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden; }

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both; }

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left; }

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939; }

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939; }

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%; }

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%; }

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield; }

.flatpickr-time input.flatpickr-hour {
  font-weight: bold; }

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400; }

.flatpickr-time input:focus {
  outline: 0;
  border: 0; }

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center; }

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400; }

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #eee; }

.flatpickr-input[readonly] {
  cursor: pointer; }

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

/**
 * IMPORT FONTS
 * ------------
 * insert fonts in .build/.fonts
 * execute gulp fonts
 */
/* open-sans-condensed-300 - latin */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/googlefonts/open-sans-condensed-v15-latin-300.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/googlefonts/open-sans-condensed-v15-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/googlefonts/open-sans-condensed-v15-latin-300.woff2") format("woff2"), url("../fonts/googlefonts/open-sans-condensed-v15-latin-300.woff") format("woff"), url("../fonts/googlefonts/open-sans-condensed-v15-latin-300.ttf") format("truetype"), url("../fonts/googlefonts/open-sans-condensed-v15-latin-300.svg#OpenSansCondensed") format("svg");
  /* Legacy iOS */
  font-display: swap; }

/* open-sans-condensed-700 - latin */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/googlefonts/open-sans-condensed-v15-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/googlefonts/open-sans-condensed-v15-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/googlefonts/open-sans-condensed-v15-latin-700.woff2") format("woff2"), url("../fonts/googlefonts/open-sans-condensed-v15-latin-700.woff") format("woff"), url("../fonts/googlefonts/open-sans-condensed-v15-latin-700.ttf") format("truetype"), url("../fonts/googlefonts/open-sans-condensed-v15-latin-700.svg#OpenSansCondensed") format("svg");
  /* Legacy iOS */
  font-display: swap; }

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/googlefonts/open-sans-v27-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/googlefonts/open-sans-v27-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/googlefonts/open-sans-v27-latin-regular.woff2") format("woff2"), url("../fonts/googlefonts/open-sans-v27-latin-regular.woff") format("woff"), url("../fonts/googlefonts/open-sans-v27-latin-regular.ttf") format("truetype"), url("../fonts/googlefonts/open-sans-v27-latin-regular.svg#OpenSans") format("svg");
  /* Legacy iOS */
  font-display: swap; }

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/googlefonts/open-sans-v27-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/googlefonts/open-sans-v27-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/googlefonts/open-sans-v27-latin-700.woff2") format("woff2"), url("../fonts/googlefonts/open-sans-v27-latin-700.woff") format("woff"), url("../fonts/googlefonts/open-sans-v27-latin-700.ttf") format("truetype"), url("../fonts/googlefonts/open-sans-v27-latin-700.svg#OpenSans") format("svg");
  /* Legacy iOS */
  font-display: swap; }

/* sedgwick-ave-regular - latin */
@font-face {
  font-family: 'Sedgwick Ave';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/googlefonts/sedgwick-ave-v7-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/googlefonts/sedgwick-ave-v7-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/googlefonts/sedgwick-ave-v7-latin-regular.woff2") format("woff2"), url("../fonts/googlefonts/sedgwick-ave-v7-latin-regular.woff") format("woff"), url("../fonts/googlefonts/sedgwick-ave-v7-latin-regular.ttf") format("truetype"), url("../fonts/googlefonts/sedgwick-ave-v7-latin-regular.svg#SedgwickAve") format("svg");
  /* Legacy iOS */
  font-display: swap; }

@font-face {
  font-family: 'Summer Loving Regular';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  font-stretch: normal;
  src: url(../fonts/googlefonts/Summer-Loving-Regular.otf) format("opentype");
  unicode-range: U+0-10FFFF; }

/*** IMPORT BASIC STUFF ***/
/* Fluid for mobile (375px) */
/* Fluid up to xl */
/* Fluid up to xlplus */
/* Fluid up to outer */
/*
  fluid mixin
  - provides a fluid layout for mobile and px layout for everything else
  - one value for both sizes
 */
/*
  fluidDiff mixin
  - provides a fluid layout for mobile and px layout for everything else
  - different values for both sizes
 */
/* Container Mimicry */
/* Outline Button */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  color: #000;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1em; }

p {
  margin-top: 0;
  margin-bottom: 1em; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: 700; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #81AFAD;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #629b98;
    text-decoration: none; }

.area a:not(.btn):not(.area--disruptor-badge):not(.accordion-link) {
  border-bottom: 2px solid transparent;
  transition: 0.3s ease; }
  .area a:not(.btn):not(.area--disruptor-badge):not(.accordion-link):hover {
    padding: 0 0 2px 0;
    border-color: #81AFAD; }

.microsite .area--text a, .microsite .area--infobox a, .microsite .area--teaser a, .microsite .area--disruptor a, .microsite .area--textmedia a {
  color: var(--micrositeAreaPrimaryColor); }
  .microsite .area--text a:hover, .microsite .area--infobox a:hover, .microsite .area--teaser a:hover, .microsite .area--disruptor a:hover, .microsite .area--textmedia a:hover {
    color: var(--micrositeAreaPrimaryColor); }

.microsite .area a:not(.btn):not(.area--disruptor-badge):not(.accordion-link):hover {
  border-color: var(--micrositeAreaPrimaryColor); }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #000000;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin: 0; }

button {
  border-radius: 0; }

button:focus {
  /*outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;*/
  outline: none; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.embed-responsive {
  position: relative;
  width: 100%;
  height: 0; }
  .embed-responsive-1by1 {
    padding-bottom: 100%; }
  .embed-responsive-3by2 {
    padding-bottom: 66.66666%; }
  .embed-responsive-16by9 {
    padding-bottom: 56.25%; }
  .embed-responsive > * {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  .embed-responsive > iframe {
    border: none; }

.plant {
  position: absolute;
  top: -60px;
  width: 260px;
  height: 230px;
  background-repeat: no-repeat;
  background-size: contain; }
  @media (max-width: 991.98px) {
    .plant {
      width: 180px;
      height: 150px; } }
  @media (max-width: 575.98px) {
    .plant {
      width: 110px;
      height: 80px;
      top: -20px; } }
  .plant-left {
    background-image: svg-load("../images/layout/svg/container-plant-left.svg");
    background-position: left;
    left: 0; }
  .plant-right {
    background-image: svg-load("../images/layout/svg/container-plant-right.svg");
    background-position: right;
    right: 0; }

.block {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  font-size: 16px; }

.block h2 {
  position: relative;
  display: block;
  text-align: center;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 10vw;
  color: white;
  font-weight: 400; }

.img-parallax {
  width: 100vmax;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  pointer-events: none; }

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%; }

.block {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  font-size: 16px; }

.block h2 {
  position: relative;
  display: block;
  text-align: center;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 10vw;
  color: white;
  font-weight: 400; }

.img-parallax {
  width: 100vmax;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  pointer-events: none; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  line-height: 1.2;
  font-family: "Open Sans Condensed", sans-serif;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0;
  word-break: break-all;
  text-transform: uppercase;
  hyphens: auto;
  word-break: break-all; }
  @supports (hyphens: auto) {
    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
      word-break: normal; } }
  @media (min-width: 992px) {
    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
      word-break: normal; } }
  h1 a, h2 a, h3 a, h4 a, h5 a, h6 a,
  .h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a {
    color: inherit;
    text-decoration: none; }
    h1 a:hover, h1 a:focus, h2 a:hover, h2 a:focus, h3 a:hover, h3 a:focus, h4 a:hover, h4 a:focus, h5 a:hover, h5 a:focus, h6 a:hover, h6 a:focus,
    .h1 a:hover,
    .h1 a:focus, .h2 a:hover, .h2 a:focus, .h3 a:hover, .h3 a:focus, .h4 a:hover, .h4 a:focus, .h5 a:hover, .h5 a:focus, .h6 a:hover, .h6 a:focus {
      color: inherit;
      text-decoration: none; }
  h1 small, h2 small, h3 small, h4 small, h5 small, h6 small,
  .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small {
    display: block;
    margin-top: 0.25em;
    line-height: 1.15;
    font-size: .6em;
    font-weight: 400;
    word-break: normal; }

h1, .h1 {
  font-size: 50px; }
  @media screen and (min-width: 768px) {
    h1, .h1 {
      font-size: calc(50px + 30 * ((100vw - 768px) / 432)); } }
  @media screen and (min-width: 1200px) {
    h1, .h1 {
      font-size: 80px; } }

h2, .h2 {
  font-size: 40px; }
  @media screen and (min-width: 768px) {
    h2, .h2 {
      font-size: calc(40px + 25 * ((100vw - 768px) / 432)); } }
  @media screen and (min-width: 1200px) {
    h2, .h2 {
      font-size: 65px; } }

h3, .h3 {
  font-size: 35px; }
  @media screen and (min-width: 768px) {
    h3, .h3 {
      font-size: calc(35px + 10 * ((100vw - 768px) / 432)); } }
  @media screen and (min-width: 1200px) {
    h3, .h3 {
      font-size: 45px; } }

@media (max-width: 767.98px) {
  h3 small, .h3 small {
    font-size: 16px; } }

h4, .h4 {
  font-size: 25px; }
  @media screen and (min-width: 768px) {
    h4, .h4 {
      font-size: calc(25px + 5 * ((100vw - 768px) / 432)); } }
  @media screen and (min-width: 1200px) {
    h4, .h4 {
      font-size: 30px; } }

h4 small, .h4 small {
  font-size: .8em; }
  @media (max-width: 767.98px) {
    h4 small, .h4 small {
      font-size: 16px; } }

h5, .h5 {
  font-size: 25px; }
  @media screen and (min-width: 768px) {
    h5, .h5 {
      font-size: calc(25px + 0 * ((100vw - 768px) / 432)); } }
  @media screen and (min-width: 1200px) {
    h5, .h5 {
      font-size: 25px; } }

h5 small, .h5 small {
  font-size: 1em; }

h6, .h6 {
  font-size: 16px; }
  @media screen and (min-width: 768px) {
    h6, .h6 {
      font-size: calc(16px + 0 * ((100vw - 768px) / 432)); } }
  @media screen and (min-width: 1200px) {
    h6, .h6 {
      font-size: 16px; } }

h6 small, .h6 small {
  font-size: 1em; }

.special-headline {
  font-family: "Summer Loving Regular", serif;
  transform: rotate(-5deg); }
  .special-headline {
    font-size: 100px; }
    @media screen and (min-width: 768px) {
      .special-headline {
        font-size: calc(100px + 60 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .special-headline {
        font-size: 160px; } }
  .special-headline {
    line-height: 60px; }
    @media screen and (min-width: 768px) {
      .special-headline {
        line-height: calc(60px + 40 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .special-headline {
        line-height: 100px; } }

hr {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
  opacity: 1;
  border: 1px dashed #81AFAD; }
  @media (min-width: 992px) {
    hr {
      margin-top: 3.75rem;
      margin-bottom: 3.75rem; } }

p {
  margin-bottom: 1em; }

.lead {
  margin-bottom: 1.2em;
  font-weight: 600;
  line-height: 26px; }
  .lead {
    font-size: 18px; }
    @media screen and (min-width: 768px) {
      .lead {
        font-size: calc(18px + 0 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .lead {
        font-size: 18px; } }
  .lead strong {
    font-weight: 900; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-justify {
  text-align: justify !important; }

.text-red {
  color: #CC0000 !important; }

.text-small {
  font-size: 12px; }

.microsite .text-red {
  color: var(--micrositeHeadlineColor) !important; }

/*** IMPORT LAYOUT ***/
html {
  scroll-behavior: smooth; }

body {
  overflow-x: hidden;
  width: 100%; }
  @media (max-width: 767.98px) {
    .offcanvas-nav--visible body {
      overflow-y: hidden; } }

::selection {
  background: #81AFAD;
  color: #fff; }

ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.area--text ul li {
  position: relative;
  padding-left: 30px; }
  .area--text ul li:before {
    content: svg-load("../images/layout/svg/li.svg");
    position: absolute;
    left: 0; }

.layer-banner {
  max-width: calc(100vw - 60px);
  max-height: calc(100vh - 60px);
  width: 640px;
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: #B15B19FF;
  z-index: 9;
  padding: 50px;
  opacity: 0;
  display: none;
  transform: translate(-50%, -40%);
  transition: opacity 0.3s, transform 0.3s;
  border-radius: 20px;
  overflow: hidden; }
  .layer-banner.is-visible {
    opacity: 1;
    transform: translate(-50%, -50%);
    display: block; }
  .layer-banner--media, .layer-banner img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .layer-banner img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .layer-banner--content {
    position: relative;
    z-index: 1;
    color: #000000;
    word-break: break-word;
    text-align: center; }
    .layer-banner--content h1 {
      font-weight: 700;
      font-size: 2em;
      text-transform: uppercase; }
    .layer-banner--content p {
      font-weight: 700;
      font-size: 1.5em;
      margin-bottom: 0; }
  .layer-banner #close-banner {
    background: none;
    border: none;
    position: absolute;
    right: 20px;
    top: 20px;
    padding: 0;
    cursor: pointer;
    z-index: 1; }
    .layer-banner #close-banner i {
      height: 20px;
      width: 20px;
      display: block; }
      .layer-banner #close-banner i:before, .layer-banner #close-banner i:after {
        content: '';
        height: 3px;
        width: 100%;
        background-color: #000000;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%; }
      .layer-banner #close-banner i:before {
        transform: translate(-50%, -50%) rotate(45deg); }
      .layer-banner #close-banner i:after {
        transform: translate(-50%, -50%) rotate(-45deg); }
  .layer-banner .text-black {
    color: #000000; }
  .layer-banner .text-white {
    color: #ffffff; }
  @media (max-width: 575px) {
    .layer-banner {
      padding: 40px 30px; } }

.brauunion {
  height: 80px;
  background-color: #ebd21d;
  width: 100%; }

.page-header {
  position: relative; }
  .page-header .social-media, .page-header .main-nav--toggle {
    z-index: 10; }
  .page-header-bar {
    position: fixed;
    height: 60px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9;
    background-color: #81AFAD; }
    @media (min-width: 1200px) {
      .page-header-bar {
        height: 80px;
        top: 40px;
        display: grid;
        grid-template-areas: 'logo nav meta';
        grid-template-columns: 1fr 4fr 1fr; }
        .page-header-bar--meta {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          background-color: #81AFAD; } }
    .page-header-bar-logo {
      height: 100%;
      position: relative;
      z-index: 9; }
      @media (min-width: 1200px) {
        .page-header-bar-logo {
          grid-area: logo; } }
      .page-header-bar-logo .logo {
        position: absolute; }
        .page-header-bar-logo .logo {
          height: 75px; }
          @media screen and (min-width: 768px) {
            .page-header-bar-logo .logo {
              height: calc(75px + 60 * ((100vw - 768px) / 432)); } }
          @media screen and (min-width: 1200px) {
            .page-header-bar-logo .logo {
              height: 135px; } }
        .page-header-bar-logo .logo {
          min-width: 180px; }
          @media screen and (min-width: 768px) {
            .page-header-bar-logo .logo {
              min-width: calc(180px + 170 * ((100vw - 768px) / 432)); } }
          @media screen and (min-width: 1200px) {
            .page-header-bar-logo .logo {
              min-width: 350px; } }
        @media (max-width: 767.98px) {
          .page-header-bar-logo .logo {
            height: 60px;
            background-position: center; } }
        @media (max-width: 1199.98px) {
          .page-header-bar-logo .logo {
            width: unset; } }
        @media (min-width: 1200px) and (max-width: 1599.98px) {
          .page-header-bar-logo .logo {
            min-width: 100% !important;
            height: 100% !important;
            top: 50%; } }
  .page-header--icon {
    position: absolute;
    right: 0; }
    .page-header--icon {
      bottom: 30px; }
      @media screen and (min-width: 768px) {
        .page-header--icon {
          bottom: calc(30px + 30 * ((100vw - 768px) / 432)); } }
      @media screen and (min-width: 1200px) {
        .page-header--icon {
          bottom: 60px; } }
  .page-header .hamburger--box, .page-header [for="toggleMobileNav"] {
    height: 19px;
    width: 30px;
    display: block; }
  .page-header .hamburger--box {
    position: relative; }
    @media (min-width: 1200px) {
      .page-header .hamburger--box {
        display: none; } }
    .page-header .hamburger--box-inner {
      background-color: #fff;
      display: block;
      position: absolute;
      top: 8px;
      width: 100%;
      height: 3px; }
      .page-header .hamburger--box-inner::before, .page-header .hamburger--box-inner::after {
        content: '';
        width: 100%;
        height: 3px;
        background-color: #fff;
        display: block;
        position: absolute;
        transition: transform 0.3s; }
      .page-header .hamburger--box-inner::before {
        top: -8px; }
      .page-header .hamburger--box-inner::after {
        bottom: -8px; }
  .page-header .social-media {
    position: absolute;
    left: 20px;
    top: 17px;
    display: flex; }
    @media (min-width: 1200px) {
      .page-header .social-media {
        position: unset;
        grid-area: meta;
        border-left: 1px dashed #fff;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding-left: 40px;
        row-gap: 5px;
        width: 100%; } }
    @media (max-width: 767.98px) {
      .page-header .social-media .icon--instagram {
        height: 1.5em;
        width: 1.5em; } }
    .page-header .social-media .lang-nav {
      display: none; }
      @media (min-width: 1200px) {
        .page-header .social-media .lang-nav {
          display: block; } }
    .page-header .social-media > li:first-child {
      margin-right: 10px; }
    .page-header .social-media li {
      display: inline-block; }
      @media (min-width: 1200px) {
        .page-header .social-media li {
          display: inline-block;
          margin: 0 20px; }
          .page-header .social-media li a {
            color: #fff;
            text-transform: uppercase; } }

.frontpage .page-header--icon {
  transition: transform 0.3s; }
  .frontpage .page-header--icon {
    bottom: 160px; }
    @media screen and (min-width: 768px) {
      .frontpage .page-header--icon {
        bottom: calc(160px + 160 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .frontpage .page-header--icon {
        bottom: 320px; } }
  .frontpage .page-header--icon:hover {
    transform: scale(1.3); }

.microsite .page-header-bar {
  background-color: var(--micrositeHeaderbarColor);
  grid-template-columns: 2fr 4fr .5fr; }
  .microsite .page-header-bar--meta {
    display: none; }
  .microsite .page-header-bar-logo {
    display: grid;
    grid-template-columns: repeat(2, 1fr); }
    @media (max-width: 1199.98px) {
      .microsite .page-header-bar-logo {
        margin-right: 60px; } }
    .microsite .page-header-bar-logo .logo-wrap {
      width: 100%;
      height: 100%;
      position: relative; }
      .microsite .page-header-bar-logo .logo-wrap a {
        top: 50%;
        transform: translateY(-50%); }
    .microsite .page-header-bar-logo .logo {
      min-width: unset;
      width: 120%;
      height: 150%;
      transition: height .3s, width .3s;
      background-size: contain; }
      @media (max-width: 1199.98px) {
        .microsite .page-header-bar-logo .logo {
          left: unset; } }
      @media (max-width: 1399.98px) {
        .microsite .page-header-bar-logo .logo {
          width: 100%;
          height: 100%; } }
    .microsite .page-header-bar-logo .partner-logo {
      position: absolute;
      width: 120%;
      height: 120%;
      object-fit: contain;
      transition: height .3s, width .3s; }
      @media (max-width: 1599.98px) {
        .microsite .page-header-bar-logo .partner-logo {
          width: 100%;
          height: 90%; } }

.scroll-direction--down .microsite .page-header-bar-logo .logo {
  min-width: unset;
  width: 100%;
  height: 100%; }

.scroll-direction--down .microsite .page-header-bar-logo .partner-logo {
  position: absolute;
  width: 100%;
  height: 90%; }

.disturber {
  display: block;
  width: 130px;
  height: 130px;
  position: absolute;
  right: 30px;
  top: 30px; }
  @media screen and (min-width: 1550px) and (max-width: 1700px) {
    .disturber {
      left: unset;
      right: 50px;
      width: 130px;
      height: 130px;
      top: 40px; }
      .disturber img {
        width: 100%;
        height: auto; } }
  @media screen and (min-width: 1200px) and (max-width: 1550px) {
    .disturber {
      right: 225px;
      left: unset;
      top: 20px; } }
  @media screen and (min-width: 1700px) {
    .disturber {
      position: fixed;
      right: unset;
      left: 410px;
      top: 60px;
      z-index: 1000; } }

.header-media {
  position: relative;
  min-height: 250px;
  overflow: hidden;
  height: 650px;
  margin-top: 120px; }
  @media (max-width: 1199.98px) {
    .header-media {
      margin-top: 60px;
      height: 650px; } }
  @media (max-width: 767.98px) {
    .header-media {
      height: 250px; } }
  .header-media > img,
  .header-media > picture,
  .header-media > picture > img,
  .header-media > video,
  .header-media > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .header-media > img,
  .header-media > picture > img,
  .header-media > video {
    object-fit: cover; }

.frontpage .header-media {
  height: calc(100vh - 120px); }
  @media (max-width: 1199.98px) {
    .frontpage .header-media {
      margin-top: 60px;
      height: calc(100vh - 60px); } }
  @media (max-width: 767.98px) {
    .frontpage .header-media {
      height: 400px; } }

.main-content {
  padding-top: 60px;
  padding-bottom: 60px; }
  @media (min-width: 992px) {
    .main-content {
      padding-top: 100px;
      padding-bottom: 100px; } }
  :root {
    --scrollbarWidth: 0px; }

.footer--main {
  background-color: #F3F8F9;
  padding-top: 160px; }
  @media (max-width: 767.98px) {
    .footer--main {
      padding-top: 80px; } }
  .footer--main-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 30px; }
    @media (max-width: 575.98px) {
      .footer--main-content {
        flex-direction: column;
        gap: 0; } }
    .footer--main-content .col {
      flex: 1 0 auto;
      max-width: 330px; }
      @media (max-width: 575.98px) {
        .footer--main-content .col {
          max-width: unset; } }
  .footer--main-pages {
    display: flex;
    max-width: unset !important; }
    @media (max-width: 575.98px) {
      .footer--main-pages {
        flex-direction: column; } }
    .footer--main-pages .footer--main-links {
      width: 100%; }
      .footer--main-pages .footer--main-links:first-child {
        margin-right: 30px; }
        @media (max-width: 575.98px) {
          .footer--main-pages .footer--main-links:first-child {
            margin-right: 0; } }
  .footer--main-content {
    margin-bottom: 60px; }
  @media (max-width: 575.98px) {
    .footer--main-contact {
      margin-bottom: 30px;
      text-align: center; } }
  .footer--main-contact h5 {
    margin-bottom: 40px; }
  .footer--main-contact p:nth-child(2) {
    margin-bottom: 0; }
  .footer--main-links li {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    transition: background-color 0.3s; }
    .footer--main-links li:hover {
      background-color: #81AFAD; }
      .footer--main-links li:hover a {
        color: #fff; }
      .footer--main-links li:hover .angle-grey {
        opacity: 1;
        border-color: #fff; }
    .footer--main-links li a {
      width: 100%; }
  .footer--main-icons {
    margin-top: 65px; }
    @media (max-width: 575.98px) {
      .footer--main-icons {
        display: flex;
        justify-content: center;
        margin-bottom: 60px;
        margin-top: 0; }
        .footer--main-icons li {
          margin-bottom: 0; }
          .footer--main-icons li:nth-child(2) {
            margin: 0 15px; } }
    .footer--main-icons li {
      display: flex;
      align-items: center;
      margin-bottom: 15px; }
    .footer--main-icons span {
      height: 40px;
      width: 40px;
      background-color: #CC0000;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%; }
    .footer--main-icons i {
      height: 25px;
      width: 25px; }
    .footer--main-icons a:not(.footer-icons) {
      margin-left: 15px; }
      @media (max-width: 575.98px) {
        .footer--main-icons a:not(.footer-icons) {
          display: none; } }
  .footer--main-partner {
    text-align: center;
    margin-bottom: 40px; }
    .footer--main-partner-headline {
      position: relative;
      height: 12px;
      margin-bottom: 40px; }
      .footer--main-partner-headline p {
        margin-bottom: 0;
        background-color: #F3F8F9;
        position: absolute;
        font-size: 12px;
        line-height: 12px;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
        width: fit-content; }
      .footer--main-partner-headline span {
        position: absolute;
        height: calc(50% + 1px);
        border-top: 1px dashed #445572;
        width: 100%;
        bottom: 0;
        left: 0; }
    .footer--main-partner li {
      display: inline-block;
      margin-right: 80px; }
      .footer--main-partner li:last-child {
        margin-right: 0; }
      @media (max-width: 1199.98px) {
        .footer--main-partner li {
          margin: 20px; }
          .footer--main-partner li:last-child {
            margin-right: unset; } }
      .footer--main-partner li a:hover img {
        filter: unset;
        opacity: 1; }
      .footer--main-partner li img {
        filter: grayscale(100%) brightness(75%) contrast(2);
        opacity: 0.5;
        transition: opacity 0.3s, filter 0.3s; }
  .footer--main-copyright {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background-color: #81AFAD;
    padding: 30px;
    color: #fff; }
    .footer--main-copyright p {
      margin: .5em 0;
      color: #fff !important; }
    .footer--main-copyright svg {
      margin: .5em 0; }
    @media (min-width: 992px) {
      .footer--main-copyright {
        padding: 30px 60px; } }
  .footer--main a, .footer--main p, .footer--main h5 {
    color: #445572; }
  .footer--main .conecto-copyright {
    background-image: svg-load("../images/layout/svg/conecto.svg");
    background-repeat: no-repeat; }

.microsite .footer--main-content {
  justify-content: flex-start !important; }

.microsite .footer--main-contact p, .microsite .footer--main-contact h5 {
  color: inherit !important; }

@media screen and (min-width: 606px) {
  .microsite .footer--main-contact:last-child {
    margin-top: 70px; } }

.microsite .footer--main-links li:hover {
  background-color: #fff !important; }
  .microsite .footer--main-links li:hover .angle-grey {
    opacity: .1;
    border-color: #445572; }

.microsite .footer--main-icons {
  margin-top: 0 !important; }
  @media (max-width: 575.98px) {
    .microsite .footer--main-icons {
      flex-direction: column;
      margin-bottom: 0; } }
  .microsite .footer--main-icons li {
    margin-bottom: 15px; }
  .microsite .footer--main-icons li:nth-child(2) {
    margin: 0 0 15px 0 !important; }
  .microsite .footer--main-icons a {
    display: block !important; }

.microsite .footer--main-copyright {
  display: block; }
  .microsite .footer--main-copyright a {
    color: #fff !important; }

/*** IMPORT COMPONENTS ***/
.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 60px;
  padding-right: 60px;
  max-width: 1260px; }
  @media (max-width: 575.98px) {
    .container {
      padding-left: 30px;
      padding-right: 30px; } }
  .container--narrow {
    max-width: 960px; }
  .container--full-width {
    max-width: none;
    width: 100%; }

.logo {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-image: svg-load("../images/layout/svg/subpage-logo-camps.svg");
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
  background-position: center; }
  @media (min-width: 1200px) {
    .logo {
      left: 10px; } }
  @media (max-width: 1199.98px) {
    .logo {
      left: 50%;
      transform: translateX(-50%); } }
  @media (max-width: 575.98px) {
    .logo {
      height: 65px;
      background-position: top; } }

@media (max-width: 575.98px) {
  .frontpage .logo {
    left: 50%;
    top: 50%;
    transform: translateX(-50%); } }

.frontpage .logo .slogan {
  width: 100%;
  height: calc(100% / 4);
  position: absolute;
  bottom: -10px;
  background-image: svg-load("../images/layout/svg/frontpage-logo-slogan-camps.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transition: opacity 1s, transform 1s; }
  .frontpage .logo .slogan.disapear {
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 1s, transform 1s; }

.btn {
  display: inline-block;
  padding: 20px 30px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.17;
  color: #fff;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 30px;
  box-shadow: none;
  transition: background-color .3s, color .3s;
  text-transform: uppercase; }
  .btn:hover {
    color: #000;
    text-decoration: none;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1); }
  .btn:focus {
    outline: 0;
    box-shadow: none; }
  .btn:disabled, .btn.disabled {
    pointer-events: none;
    opacity: 0.4; }
  .btn-block {
    display: block; }
  .btn-primary {
    color: #fff !important;
    background-color: #CC0000 !important;
    border-color: #CC0000 !important;
    animation: jittery 6s infinite; }
    .btn-primary:hover {
      color: #CC0000;
      background-color: #fff;
      border-color: #fff; }
  .btn-secondary {
    color: #fff !important;
    background-color: #81AFAD !important;
    border-color: #81AFAD !important;
    transition: transform 0.3s; }
    .btn-secondary:hover {
      color: #81AFAD;
      background-color: #fff;
      border-color: #fff; }
    .btn-secondary:hover {
      transform: translateY(-6px); }
  .btn-outline {
    color: #000 !important;
    background-color: transparent !important;
    border-color: #000 !important;
    border-width: 2px; }
    .btn-outline:hover {
      color: #000;
      background-color: #fff;
      border-color: #fff; }
  .btn-lg {
    font-size: 19.2px; }
  .btn-sm {
    font-size: 14.4px;
    padding: 15px 25px; }

.microsite .btn {
  background-color: var(--micrositeAreaPrimaryColor) !important;
  border-color: var(--micrositeAreaPrimaryColor) !important; }

.main-nav {
  position: fixed;
  top: 57px;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 8;
  background-color: #81AFAD;
  opacity: 0;
  transform: scale(0);
  transition: opacity ease 0.3s, transform ease 0.3s;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 40px;
  overflow: scroll; }
  @media (min-width: 768px) and (max-width: 1199.98px) {
    .main-nav {
      left: 50%; } }
  @media (min-width: 1200px) {
    .main-nav {
      opacity: unset;
      transform: unset;
      bottom: unset;
      padding: 0;
      right: 200px;
      left: 220px;
      grid-area: nav;
      position: unset;
      overflow: unset;
      height: unset; } }
  @media (min-width: 1200px) {
    .main-nav--list {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%; } }
  .main-nav--item {
    margin-bottom: 30px; }
    @media (min-width: 1200px) {
      .main-nav--item {
        height: 100%;
        display: flex;
        align-items: center;
        margin-right: 60px;
        margin-bottom: 0; }
        .main-nav--item:last-child {
          margin-right: 60px; } }
    .main-nav--item label {
      height: 40px;
      width: 40px;
      float: right;
      border: 1px solid #fff;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center; }
      @media (min-width: 1200px) {
        .main-nav--item label {
          display: none; } }
      .main-nav--item label i {
        transition: transform 0.3s;
        margin-left: 2px; }
    .main-nav--item > a {
      font-size: 20px;
      font-weight: 700;
      line-height: 40px;
      text-transform: uppercase; }
      @media (min-width: 1200px) {
        .main-nav--item > a {
          line-height: 30px;
          text-align: center; } }
    .main-nav--item ul {
      max-height: 0;
      visibility: hidden; }
      @media (min-width: 1200px) {
        .main-nav--item ul {
          display: none;
          width: 100vw; }
          .main-nav--item ul:before {
            content: "";
            background-color: #F3F8F9;
            display: inline-block;
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: -1;
            left: -100%; } }
    .main-nav--item li {
      background-color: #fff;
      opacity: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      transition: background-color 0.3s; }
      @media (min-width: 1200px) {
        .main-nav--item li {
          width: fit-content;
          min-width: 360px; } }
      .main-nav--item li:not(:last-child) {
        margin-bottom: 5px; }
        @media (min-width: 1200px) {
          .main-nav--item li:not(:last-child) {
            margin-bottom: 10px; } }
      @media (min-width: 1200px) {
        .main-nav--item li:first-child {
          margin-top: 60px; } }
      .main-nav--item li:last-child {
        margin-bottom: 30px; }
        @media (min-width: 1200px) {
          .main-nav--item li:last-child {
            margin-bottom: 60px; } }
      .main-nav--item li:hover {
        background-color: #81AFAD; }
        .main-nav--item li:hover a {
          color: #fff; }
        .main-nav--item li:hover .angle-grey {
          opacity: 1;
          border-color: #fff; }
      .main-nav--item li a {
        color: #445572;
        display: inline-block;
        width: 100%; }
    @media (min-width: 1200px) {
      .main-nav--item.has-dropdown {
        position: relative; }
        .main-nav--item.has-dropdown:hover .dot {
          border-radius: 100%; }
          .main-nav--item.has-dropdown:hover .dot.big {
            height: 20px;
            width: 20px;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: -10px;
            left: calc(50% - 5px);
            z-index: 9; }
          .main-nav--item.has-dropdown:hover .dot.small {
            height: 10px;
            width: 10px;
            background-color: #CC0000;
            display: block; }
        .main-nav--item.has-dropdown:hover ul {
          display: block;
          flex-direction: column;
          position: absolute;
          top: 100%;
          background-color: #F3F8F9;
          visibility: visible;
          max-height: 1000px; }
          .main-nav--item.has-dropdown:hover ul li {
            opacity: 1; } }
  .main-nav a {
    color: #fff; }
  .main-nav .partner-sites {
    display: flex;
    margin-bottom: 50px; }
    @media (min-width: 1200px) {
      .main-nav .partner-sites {
        position: absolute;
        top: -40px;
        left: 0;
        right: 0;
        height: 40px;
        background-color: #fff;
        text-align: end;
        padding-right: 70px;
        display: unset;
        margin-bottom: 0; }
        .main-nav .partner-sites li {
          display: inline-block;
          width: unset;
          margin-top: 8px; }
          .main-nav .partner-sites li:not(:last-child) {
            padding-right: 30px; }
        .main-nav .partner-sites a {
          color: #CC0000; } }
    .main-nav .partner-sites a {
      color: #fff;
      font-size: 12px;
      font-weight: 600; }
    .main-nav .partner-sites li {
      width: 100%;
      justify-self: center;
      text-align: center; }
      .main-nav .partner-sites li:first-child {
        text-align: start; }
      .main-nav .partner-sites li:last-child {
        text-align: end; }
      .main-nav .partner-sites li:not(:last-child) {
        border-right: 1px dashed #fff; }

[for="toggleMobileNav"] {
  position: absolute;
  top: 21px;
  right: 20px; }
  @media (min-width: 1200px) {
    [for="toggleMobileNav"] {
      display: none !important; } }

[name="toggleMobileNav"] {
  display: none; }
  [name="toggleMobileNav"]:checked ~ .page-wrap .page-header label[for="toggleMobileNav"] .hamburger--box-inner {
    background-color: transparent; }
    [name="toggleMobileNav"]:checked ~ .page-wrap .page-header label[for="toggleMobileNav"] .hamburger--box-inner:before {
      top: 0;
      transform: rotate(45deg); }
    [name="toggleMobileNav"]:checked ~ .page-wrap .page-header label[for="toggleMobileNav"] .hamburger--box-inner:after {
      top: 0;
      transform: rotate(-45deg); }
  [name="toggleMobileNav"]:checked ~ .page-wrap .page-header .main-nav {
    opacity: 1;
    transform: scale(1); }

[name="toggleSubNav"] {
  display: none; }
  [name="toggleSubNav"]:checked + label {
    background-color: rgba(0, 0, 0, 0.1);
    border-color: transparent; }
    [name="toggleSubNav"]:checked + label i {
      transform: rotate(90deg);
      margin-left: 0;
      margin-top: 3px; }
  [name="toggleSubNav"]:checked ~ ul {
    visibility: visible;
    max-height: 1000px;
    transition: max-height 0.5s; }
    [name="toggleSubNav"]:checked ~ ul li {
      opacity: 1;
      transition: opacity 0.5s; }
      [name="toggleSubNav"]:checked ~ ul li:first-child {
        margin-top: 30px; }
        @media (min-width: 1200px) {
          [name="toggleSubNav"]:checked ~ ul li:first-child {
            margin-top: 60px; } }

.angle {
  height: 10px;
  width: 10px;
  display: inline-block;
  margin-right: 15px;
  transform: rotate(45deg); }
  .angle.right {
    border-top: 2px solid;
    border-right: 2px solid; }
  .angle.left {
    border-bottom: 2px solid;
    border-left: 2px solid; }
  .angle-grey {
    border-color: #445572;
    opacity: 0.1; }

.animate-pop-in {
  animation: pop-in 0.6s cubic-bezier(0, 0.9, 0.3, 1.2) forwards;
  opacity: 0; }

.microsite .main-nav {
  background-color: var(--micrositeHeaderbarColor); }
  @media (min-width: 1200px) {
    .microsite .main-nav--item ul, .microsite .main-nav--item ul:before {
      background-color: var(--micrositeNavigationColor) !important; } }
  .microsite .main-nav--item a {
    color: var(--micrositeHeaderItemColor); }
  .microsite .main-nav--item ul li {
    background-color: var(--micrositeNavigationItemBackground); }
  .microsite .main-nav--item ul li:hover {
    background-color: var(--micrositeNavigationItemBackground); }
    .microsite .main-nav--item ul li:hover a {
      color: var(--micrositeHeaderItemColor); }
    .microsite .main-nav--item ul li:hover span {
      border-color: #445572; }

.lang-nav {
  padding: 30px 0; }
  @media (min-width: 1200px) {
    .lang-nav {
      padding: 0; } }
  .lang-nav ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-align: center; }
    .lang-nav ul li {
      display: inline-block;
      text-transform: uppercase;
      color: #fff;
      padding: 0.5em;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      margin: 0 10px; }
      @media (min-width: 1200px) {
        .lang-nav ul li {
          border: none;
          padding: 0; } }

@media (min-width: 1200px) {
  .main-nav .lang-nav {
    display: none; } }

@media (max-width: 1199.98px) {
  .frontpage .lang-nav {
    padding: 60px 0 30px 0 !important; } }

.breadcrumb {
  position: relative;
  z-index: 1;
  background: #F3F8F9; }
  .breadcrumb--list {
    text-align: center;
    padding: 30px 30px 60px 30px;
    list-style: none;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center; }
    .breadcrumb--list li:not(:last-child):after {
      content: '>';
      margin-left: 5px; }
  .breadcrumb a {
    color: #445572;
    opacity: 0.5; }
  .breadcrumb--buttons {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 15px; }

.c-share {
  position: relative;
  width: 60px;
  height: 60px; }
  .c-share.icons {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0.1); }
    .c-share.icons.international, .c-share.icons.yala, .c-share.icons.share {
      animation: pop-in 0.6s cubic-bezier(0, 0.9, 0.3, 1.2) forwards; }
    .c-share.icons.international {
      border-color: #CC0000;
      animation-delay: 0.3s; }
      .c-share.icons.international i {
        height: 100%;
        width: 100%; }
    .c-share.icons.yala {
      animation-delay: 0.6s; }
    .c-share.icons.share {
      animation-delay: 0.9s; }

.c-share__input {
  display: none; }
  .c-share__input:checked ~ .c-share__toggler .c-share__icon {
    transition: 0s;
    width: 0;
    height: 0;
    color: #fff; }
    .c-share__input:checked ~ .c-share__toggler .c-share__icon::before {
      transform: rotate(-45deg); }
    .c-share__input:checked ~ .c-share__toggler .c-share__icon::after {
      transform: rotate(45deg); }
  .c-share__input:checked ~ .c-share_options {
    width: 11.0625em;
    height: auto;
    border-radius: 0.3125em; }
    .c-share__input:checked ~ .c-share_options a {
      color: #fff !important;
      opacity: 1 !important; }
    .c-share__input:checked ~ .c-share_options::before,
    .c-share__input:checked ~ .c-share_options li {
      transition: 0.3s 0.15s;
      opacity: 1;
      transform: translateY(0);
      color: #fff; }

.c-share__toggler,
.c-share_options {
  position: absolute;
  right: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  background-color: #81AFAD; }

.c-share__toggler {
  cursor: pointer;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center; }

.c-share__icon {
  position: relative;
  left: -0.3125em;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  color: #fff;
  background-color: currentColor;
  box-shadow: 0.625em -0.625em, 0.625em 0.625em;
  transition: 0.1s 0.05s; }
  .c-share__icon::before, .c-share__icon::after {
    content: "";
    position: absolute;
    width: 1em;
    height: 0.125em;
    background-color: currentColor;
    transition: 0.1s; }
  .c-share__icon::before {
    top: 0.1875em;
    left: 0.1875em;
    transform-origin: bottom left;
    transform: rotate(45deg); }
  .c-share__icon::after {
    top: -0.125em;
    left: 0.03125em;
    transform-origin: center;
    transform: rotate(-45deg); }

.c-share_options {
  list-style: none;
  margin: 0;
  padding: 1.0625em 1.25em;
  box-sizing: border-box;
  overflow: hidden;
  transition: 0.2s;
  box-shadow: 0 0.125em 0.125em rgba(0, 0, 0, 0.3); }
  .c-share_options::before {
    content: attr(data-title);
    display: block;
    margin-bottom: 1.25em;
    font-weight: 700; }
  .c-share_options li {
    font-size: 0.875em;
    color: #fff;
    cursor: pointer; }
    .c-share_options li:not(:last-child) {
      margin-bottom: 0.75em; }
  .c-share_options::before,
  .c-share_options li {
    opacity: 0;
    transform: translateY(0.625em);
    transition: 0s; }

.form .breadcrumb {
  top: 120px; }
  @media (max-width: 1199.98px) {
    .form .breadcrumb {
      top: 60px; } }

.cols {
  display: grid;
  grid-column-gap: 60px;
  grid-row-gap: 60px; }
  .cols.no-gutter {
    grid-column-gap: 0;
    grid-row-gap: 0; }
  @media (min-width: 576px) {
    .cols--2 {
      grid-template-columns: repeat(2, 1fr); }
      .cols--2.ratio--25-75 {
        grid-template-columns: 25% 75%; }
      .cols--2.ratio--40-60 {
        grid-template-columns: 40% 60%; }
      .cols--2.ratio--60-40 {
        grid-template-columns: 60% 40%; }
      .cols--2.ratio--75-25 {
        grid-template-columns: 75% 25%; } }
  @media (min-width: 768px) {
    .cols--3 {
      grid-template-columns: repeat(3, 1fr); }
      .cols--3.ratio--50-25-25 {
        grid-template-columns: 50% 25% 25%; }
      .cols--3.ratio--25-50-25 {
        grid-template-columns: 25% 50% 25%; }
      .cols--3.ratio--25-25-50 {
        grid-template-columns: 25% 25% 50%; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .cols--3 {
      grid-template-columns: repeat(2, 1fr); } }
  @media (min-width: 1200px) {
    .cols--4 {
      grid-template-columns: repeat(4, 1fr); }
      .cols--4.ratio--40-20-20-20 {
        grid-template-columns: 40% 20% 20% 20%; }
      .cols--4.ratio--20-40-20-20 {
        grid-template-columns: 20% 40% 20% 20%; }
      .cols--4.ratio--20-20-40-20 {
        grid-template-columns: 20% 20% 40% 20%; }
      .cols--4.ratio--20-20-20-40 {
        grid-template-columns: 20% 20% 20% 40%; } }
  @media (min-width: 576px) and (max-width: 1199.98px) {
    .cols--4 {
      grid-template-columns: repeat(2, 1fr); } }
  @media (min-width: 1200px) {
    .cols--5 {
      grid-template-columns: repeat(5, 1fr); } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .cols--5 {
      grid-template-columns: repeat(4, 1fr); } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .cols--5 {
      grid-template-columns: repeat(3, 1fr); } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .cols--5 {
      grid-template-columns: repeat(2, 1fr); } }
  @media (min-width: 1200px) {
    .cols--6 {
      grid-template-columns: repeat(6, 1fr); } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .cols--6 {
      grid-template-columns: repeat(4, 1fr); } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .cols--6 {
      grid-template-columns: repeat(3, 1fr); } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .cols--6 {
      grid-template-columns: repeat(2, 1fr); } }
  .cols .col--2 {
    grid-column: auto / span 2; }
  .cols .col--3 {
    grid-column: auto / span 3; }
  .cols .col--4 {
    grid-column: auto / span 4; }
  .cols .col--5 {
    grid-column: auto / span 5; }
  .cols .col--6 {
    grid-column: auto / span 6; }

.area--text {
  width: 100%;
  border: none; }
  .area--text th,
  .area--text td {
    padding: 10px 15px;
    background-color: #F3F8F9; }
    @media (max-width: 767.98px) {
      .area--text th,
      .area--text td {
        padding-left: 1em !important;
        padding-right: 30px !important; } }
    .area--text th:first-child,
    .area--text td:first-child {
      padding-left: 30px; }
  .area--text thead tr:first-child th {
    background-color: #81AFAD;
    color: #fff; }
  .area--text thead tr:not(:first-child) th {
    background-color: rgba(68, 85, 114, 0.1);
    color: #445572; }
  .area--text td {
    border-bottom: 1px solid #fff; }
    .area--text td:not(:last-child) {
      border-right: 1px solid #fff; }
  .area--text p:last-child {
    margin-bottom: 0; }
  @media (max-width: 767.98px) {
    .area--text table, .area--text thead, .area--text tbody, .area--text tr, .area--text th, .area--text td {
      display: block; }
    .area--text tr:last-child td:last-child {
      border-bottom: 0; }
      .area--text tr:last-child td:last-child:before {
        border-bottom: 0; }
    .area--text.has-thead thead tr:last-child {
      display: none; }
    .area--text.has-thead tbody td {
      display: flex;
      position: relative;
      padding: 10px 15px 10px 0;
      align-items: center;
      align-content: center; }
      .area--text.has-thead tbody td:before {
        display: flex;
        align-items: center;
        align-content: center;
        content: attr(data-label);
        margin-top: -10px;
        margin-bottom: -10px;
        margin-right: 15px;
        padding: 10px 15px;
        width: 45%;
        flex: 0 0 45%;
        align-self: stretch; }
    .area--text.has-thead:not(.multiple-thead-rows) tbody td:before {
      background: #81AFAD;
      border-bottom: 1px solid rgba(68, 85, 114, 0.1);
      color: #fff; } }
  @media (max-width: 575.98px) {
    .area--text .table-responsive {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; } }
  @media (max-width: 767.98px) {
    .area--text table, .area--text tr, .area--text td {
      padding: 0; }
    .area--text table {
      border: none; }
    .area--text thead {
      display: none; }
    .area--text tr {
      float: left;
      width: 100%;
      margin-bottom: 2em; }
    .area--text td {
      float: left;
      width: 100%;
      padding: 1em; }
      .area--text td:first-child {
        background-color: #81AFAD !important;
        color: #fff !important; }
    .area--text td::before {
      content: attr(data-label);
      word-wrap: break-word;
      background-color: #F3F8F9 !important;
      float: left;
      font-weight: bold;
      color: #445572 !important; }
    .area--text td:first-child::before {
      background-color: #81AFAD !important;
      color: #fff !important; } }
  .area--text .microsite .area--text table thead tr:first-child th, .area--text .microsite table.contenttable thead tr:first-child th {
    background-color: var(--micrositeAreaPrimaryColor); }
  .area--text .microsite .area--text table td, .area--text .microsite table.contenttable td {
    background-color: var(--micrositeAreaSecondaryColor); }

.icon,
.inline-icon {
  display: inline-block;
  vertical-align: middle;
  width: 1.3em;
  height: 1.3em;
  line-height: 1.3em; }

.icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center; }
  .icon--color--white {
    filter: saturate(0) brightness(0) invert(100%) opacity(1); }

.icon--calendar-alt {
  background-image: svg-load("calendar-alt.svg"); }

.icon--calendar {
  background-image: svg-load("calendar.svg"); }

.icon--chevron-right {
  background-image: svg-load("chevron-right.svg"); }

.icon--facebook-f {
  background-image: svg-load("facebook-f.svg"); }

.icon--instagram {
  background-image: svg-load("instagram.svg"); }

.icon--youtube {
  background-image: svg-load("youtube.svg"); }

.icon--mail {
  background-image: svg-load("mail.svg"); }

.icon--phone {
  background-image: svg-load("phone.svg"); }

.icon--fax {
  background-image: svg-load("fax.svg"); }

.icon--yala {
  background-image: svg-load("yala.svg"); }

.icon--international {
  background-image: svg-load("international.svg"); }

.icon--reisepass {
  background-image: svg-load("reisepass.svg"); }

.icon--lernen {
  background-image: svg-load("lernen.svg"); }

.icon--lagerfeuer {
  background-image: svg-load("lagerfeuer.svg"); }

.icon--berge {
  background-image: svg-load("berge.svg"); }

.icon--zelt {
  background-image: svg-load("zelt.svg"); }

.icon--baum {
  background-image: svg-load("baum.svg"); }

.icon--bogen {
  background-image: svg-load("bogen.svg"); }

.icon--fernglas {
  background-image: svg-load("fernglas.svg"); }

.icon--karte {
  background-image: svg-load("karte.svg"); }

.icon--wegweiser {
  background-image: svg-load("wegweiser.svg"); }

.icon--stiefel {
  background-image: svg-load("stiefel.svg"); }

.icon--laterne {
  background-image: svg-load("laterne.svg"); }

.icon--ya {
  background-image: svg-load("ya.svg"); }

.icon--anreise {
  background-image: svg-load("anreise.svg"); }

.icon--bezahlung {
  background-image: svg-load("bezahlung.svg"); }

.icon--buchung {
  background-image: svg-load("buchung.svg"); }

.icon--corona {
  background-image: svg-load("corona.svg"); }

.icon--vor-dem-camp {
  background-image: svg-load("vor-dem-camp.svg"); }

.icon--waehrend-des-camps {
  background-image: svg-load("waehrend-des-camps.svg"); }

.accordion.active .accordion-content {
  display: block; }

.accordion.active .accordion-header .btn {
  color: #000; }

.accordion-header .btn {
  color: #81AFAD; }

.accordion-content {
  display: none; }

.accordion-body > .area:first-child {
  margin-top: 0; }

.accordion-body > .area:last-child {
  margin-bottom: 0; }

.form-container fieldset,
form.formbuilder fieldset {
  margin-bottom: 10px; }
  .form-container fieldset:last-child,
  form.formbuilder fieldset:last-child {
    margin-bottom: 0; }

.form-container label,
form.formbuilder label {
  display: block;
  margin-bottom: 5px;
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  color: #445572;
  font-weight: 600; }
  .form-container label.required:after,
  form.formbuilder label.required:after {
    content: "*"; }

.form-container input[type=email],
.form-container input[type=text],
.form-container input[type=date],
.form-container input[type=password],
.form-container select,
.form-container textarea,
form.formbuilder input[type=email],
form.formbuilder input[type=text],
form.formbuilder input[type=date],
form.formbuilder input[type=password],
form.formbuilder select,
form.formbuilder textarea {
  padding: 8px 12px;
  width: 100%;
  color: #000;
  background: #F3F8F9;
  border: 1px solid rgba(68, 85, 114, 0.2);
  border-radius: 5px;
  line-height: 1.5; }
  .form-container input[type=email]:focus,
  .form-container input[type=text]:focus,
  .form-container input[type=date]:focus,
  .form-container input[type=password]:focus,
  .form-container select:focus,
  .form-container textarea:focus,
  form.formbuilder input[type=email]:focus,
  form.formbuilder input[type=text]:focus,
  form.formbuilder input[type=date]:focus,
  form.formbuilder input[type=password]:focus,
  form.formbuilder select:focus,
  form.formbuilder textarea:focus {
    color: #000;
    background: #F3F8F9;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(129, 175, 173, 0.5); }
  .form-container input[type=email][readonly], .form-container input[type=email][disabled],
  .form-container input[type=text][readonly],
  .form-container input[type=text][disabled],
  .form-container input[type=date][readonly],
  .form-container input[type=date][disabled],
  .form-container input[type=password][readonly],
  .form-container input[type=password][disabled],
  .form-container select[readonly],
  .form-container select[disabled],
  .form-container textarea[readonly],
  .form-container textarea[disabled],
  form.formbuilder input[type=email][readonly],
  form.formbuilder input[type=email][disabled],
  form.formbuilder input[type=text][readonly],
  form.formbuilder input[type=text][disabled],
  form.formbuilder input[type=date][readonly],
  form.formbuilder input[type=date][disabled],
  form.formbuilder input[type=password][readonly],
  form.formbuilder input[type=password][disabled],
  form.formbuilder select[readonly],
  form.formbuilder select[disabled],
  form.formbuilder textarea[readonly],
  form.formbuilder textarea[disabled] {
    color: rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.1);
    border-color: rgba(68, 85, 114, 0.3); }

.form-container textarea,
form.formbuilder textarea {
  min-height: 120px; }

.form-container .checkbox,
.form-container .radio,
form.formbuilder .checkbox,
form.formbuilder .radio {
  position: relative;
  padding-left: 20px; }
  .form-container .checkbox input[type="checkbox"],
  .form-container .checkbox input[type="radio"],
  .form-container .radio input[type="checkbox"],
  .form-container .radio input[type="radio"],
  form.formbuilder .checkbox input[type="checkbox"],
  form.formbuilder .checkbox input[type="radio"],
  form.formbuilder .radio input[type="checkbox"],
  form.formbuilder .radio input[type="radio"] {
    position: absolute;
    left: 0;
    top: 0; }

.form-container .cols,
form.formbuilder .cols {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  margin-bottom: 15px; }

.form-group {
  margin-bottom: 15px; }
  .form-group.has-datepicker {
    position: relative; }
    .form-group.has-datepicker:after {
      display: block;
      content: '';
      position: absolute;
      right: 8px;
      bottom: 8px;
      width: 25px;
      height: 25px;
      background-image: svg-load("calendar.svg", fill="transparent");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain; }
  .cols .form-group {
    margin-bottom: 0; }

.message-success {
  display: none; }

.col-form-label {
  font-size: 12px;
  font-weight: 600;
  color: #445572; }

.infobox {
  border: 3px solid #58B9CD;
  border-radius: 5px;
  position: relative; }
  .infobox {
    padding: 20px; }
    @media screen and (min-width: 768px) {
      .infobox {
        padding: calc(20px + 10 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .infobox {
        padding: 30px; } }
  .infobox-logo {
    position: absolute;
    right: 30px;
    top: -44px; }
    @media (max-width: 767.98px) {
      .infobox-logo {
        right: 50%;
        transform: translateX(50%); } }
  .infobox-infografik {
    max-height: 165px; }
    @media (max-width: 767.98px) {
      .infobox-infografik {
        display: block;
        margin-right: auto;
        margin-left: auto;
        margin-top: 20px;
        margin-bottom: 30px; } }
  @media (min-width: 768px) {
    .infobox-inner {
      display: flex; }
      .infobox-inner img {
        margin-right: 30px; } }
  .infobox h4 {
    color: #58B9CD;
    margin-bottom: 1rem; }
    @media (max-width: 767.98px) {
      .infobox h4 {
        text-align: center; } }
  .infobox > *:last-child {
    margin-bottom: 0; }
  .infobox hr {
    border: 1px dashed #000;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .infobox-primary {
    padding-top: 55px;
    border-style: solid;
    border-color: #00A94F;
    margin-top: 45px !important; }
    .infobox-primary h4 {
      color: #00A94F;
      margin-top: 1rem; }

.microsite .infobox {
  border-color: var(--micrositeInfoboxColor); }

.teaserslider-header {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: absolute;
  bottom: 90px;
  width: 100%;
  animation: pop-in 0.6s cubic-bezier(0, 0.9, 0.3, 1.2) forwards;
  animation-delay: 0.6s;
  opacity: 0;
  z-index: 1; }
  @media (max-width: 1199.98px) {
    .teaserslider-header {
      flex-direction: column;
      align-items: center; } }
  .teaserslider-header--content {
    max-width: 1150px;
    background-color: #fff; }
  .teaserslider-header .slider--controls {
    position: absolute;
    bottom: -30px;
    left: 420px;
    height: 60px;
    display: flex;
    z-index: 8; }
    .teaserslider-header .slider--controls .arrow {
      margin: 0 15px; }
  .teaserslider-header--image {
    float: left;
    width: 270px;
    height: 100%;
    position: relative; }
    .teaserslider-header--image img {
      position: absolute;
      object-fit: cover;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%; }
  .teaserslider-header--text {
    float: left;
    padding-left: 60px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    max-width: 560px;
    max-height: 560px;
    overflow: hidden;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%; }
    @media (max-width: 1199.98px) {
      .teaserslider-header--text {
        display: flex;
        flex-direction: column;
        justify-content: space-between; } }
    .teaserslider-header--text h5 {
      margin-bottom: 1rem;
      color: #CC0000; }
    .teaserslider-header--text .btn {
      display: none;
      text-align: center; }
  .teaserslider-header--button {
    padding: 0 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    height: 100%;
    text-align: center; }
  .teaserslider-header .arrow {
    height: 60px;
    width: 60px;
    border-radius: 100%;
    background-color: #81AFAD;
    border-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center; }
    .teaserslider-header .arrow svg {
      filter: brightness(0) invert(1);
      height: 20px;
      width: 20px; }
    .teaserslider-header .arrow--prev {
      transform: rotate(180deg); }
    .teaserslider-header .arrow:hover {
      cursor: pointer;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1); }

@media (max-width: 1199.98px) {
  .teaserslider-header {
    position: absolute;
    bottom: -200px; }
    .teaserslider-header--image {
      display: none; }
    .teaserslider-header--text {
      padding: 30px 30px 20px 30px;
      border-right: none;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
      height: unset;
      margin: 30px 0;
      background-color: #fff; }
      .teaserslider-header--text h5 {
        text-align: center; }
      .teaserslider-header--text .btn {
        display: block;
        align-self: center;
        margin-bottom: 25px; }
    .teaserslider-header--content {
      max-width: 100%;
      background-color: transparent; }
    .teaserslider-header--button {
      display: none; }
    .teaserslider-header .slider--controls {
      height: 50px;
      right: 50%;
      bottom: 0;
      transform: translateX(50%);
      left: unset; }
      .teaserslider-header .slider--controls .arrow {
        height: 50px;
        width: 50px; }
    .teaserslider-header .splide__slide {
      display: flex;
      justify-content: center; }
  .frontpage:not(.microsite) .main-content {
    padding-top: 250px; } }

/* Navigation Animations */
@keyframes fade-slide-down {
  0% {
    opacity: 0;
    transform: translateY(-4rem); }
  100% {
    opacity: 1;
    transform: none; } }

@keyframes pop-in {
  0% {
    opacity: 0;
    transform: translateY(-4rem) scale(0.8); }
  100% {
    opacity: 1;
    transform: none; } }

/* Button Primary Animation */
@keyframes jittery {
  5%,
  50% {
    transform: scale(1); }
  10% {
    transform: scale(0.9); }
  15% {
    transform: scale(1.15); }
  20% {
    transform: scale(1.15) rotate(-5deg); }
  25% {
    transform: scale(1.15) rotate(5deg); }
  30% {
    transform: scale(1.15) rotate(-3deg); }
  35% {
    transform: scale(1.15) rotate(2deg); }
  40% {
    transform: scale(1.15) rotate(0); } }

/*** Variables ***/
/*** Styles ***/
.magazine {
  color: #333333;
  /*** Magazine Teaser ***/ }
  .magazine--list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px; }
    .magazine--list > .magazine--article {
      width: 50%;
      flex: 0 0 50%; }
      @media (min-width: 992px) {
        .magazine--list > .magazine--article {
          width: 33.333%;
          flex-basis: 33.333%; } }
      @media (max-width: 767px) {
        .magazine--list > .magazine--article {
          width: 100%;
          flex-basis: 100%; } }
    @media (min-width: 992px) {
      .magazine--list.page-1 > .magazine--article:nth-child(1), .magazine--list.page-1 > .magazine--article:nth-child(2) {
        width: 50%;
        flex-basis: 50%; } }
    @media (min-width: 992px) {
      .magazine--list.page-1 > .magazine--article:nth-child(1) .magazine--article--more, .magazine--list.page-1 > .magazine--article:nth-child(2) .magazine--article--more {
        width: auto; } }
  .magazine--pagination {
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-align: center; }
    .magazine--pagination li {
      display: inline-block;
      margin-bottom: 4px; }
      .magazine--pagination li .current {
        color: #fff;
        background: #CC0000; }
      .magazine--pagination li a {
        display: block;
        width: 50px;
        height: 50px;
        border: 2px solid #CC0000 !important;
        border-radius: 100%;
        color: #000;
        line-height: 46px;
        font-size: 17px;
        transition: background-color .3s, color .3s; }
        .magazine--pagination li a:hover {
          background: #CC0000;
          color: #fff; }
  .magazine--detail header .magazine--meta {
    padding: 0;
    margin: 0 0 40px;
    font-size: 16px; }
    .magazine--detail header .magazine--meta .magazine--icon {
      position: relative;
      top: 2px;
      width: 20px;
      height: 16px;
      vertical-align: baseline;
      filter: invert(18%) sepia(1%) saturate(0%) hue-rotate(11deg) brightness(97%) contrast(91%); }
  .magazine--btn {
    display: inline-block;
    padding: 12px 25px;
    width: 100%;
    line-height: 1.3;
    background: #CC0000;
    color: #fff;
    border: none;
    border-radius: 40px;
    text-transform: uppercase;
    font-size: 13px;
    text-align: center;
    cursor: pointer;
    transition: background-color .3s, color .3s; }
    .magazine--btn:hover {
      color: #fff;
      background-color: #333333;
      padding: 12px 25px !important; }
      .magazine--btn:hover .magazine--icon:hover {
        filter: invert(18%) sepia(1%) saturate(0%) hue-rotate(11deg) brightness(97%) contrast(91%); }
    .magazine--btn .magazine--icon {
      margin: 0 4px 0 -8px;
      filter: saturate(0) brightness(0) invert(100%) opacity(1);
      transition: filter .3s;
      font-size: 16px;
      vertical-align: bottom; }
  .magazine--article {
    padding: 0 15px 30px;
    line-height: 1.15; }
    .magazine--article a {
      text-decoration: none; }
    .magazine--article--inner {
      position: relative;
      height: 100%;
      background: #F3F8F9;
      overflow: hidden; }
      .magazine--article--inner > * {
        width: 100%;
        flex: 0 0 100%; }
      .magazine--article--inner:hover .magazine--article--media img, .magazine--article--inner:hover .magazine--article--media video {
        transform: scale(1.1); }
      .magazine--article--inner:hover .magazine--article--more {
        background-color: #333333;
        color: #fff; }
    .magazine--article--media {
      position: relative;
      overflow: hidden; }
      .magazine--article--media:before {
        display: block;
        content: '';
        padding-bottom: 56.25%;
        width: 100%;
        height: 0;
        pointer-events: none; }
      .magazine--article--media img, .magazine--article--media video {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform .3s; }
      .magazine--article--media header {
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 40px 30px 25px;
        width: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%); }
        .magazine--article--media header, .magazine--article--media header h4 {
          color: #fff; }
    .magazine--article--meta {
      margin-top: 10px;
      padding-top: 15px;
      font-size: 12px;
      border-top: 1px solid rgba(255, 255, 255, 0.4);
      white-space: nowrap; }
      .magazine--article--meta span {
        display: inline-block;
        vertical-align: bottom; }
      .magazine--article--meta .magazine--icon {
        margin-right: 5px;
        width: 16px;
        height: 14px;
        vertical-align: bottom;
        filter: saturate(0) brightness(0) invert(100%) opacity(1); }
    .magazine--article--text {
      padding: 30px 30px 25px;
      font-size: 16px;
      color: #333333;
      line-height: 1.5625; }
      .magazine--article--text > p:last-child {
        margin-bottom: 0; }
    .magazine--article--buttons {
      padding: 0 30px 40px;
      text-align: right;
      align-self: flex-end; }
  .magazine--categories {
    margin-bottom: 40px; }
    .magazine--categories .magazine--btn {
      background: transparent;
      color: #CC0000;
      border: 1px solid #CC0000 !important; }
      .magazine--categories .magazine--btn:hover {
        border: 1px solid #CC0000 !important;
        background: #CC0000;
        color: #fff; }
      .magazine--categories .magazine--btn.is-active {
        background: #CC0000;
        color: #fff; }
    .magazine--categories ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      text-align: center; }
      .magazine--categories ul li {
        display: inline-block;
        margin: 0 3px 10px; }

/*** Icons ***/
.magazine--icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: inherit;
  vertical-align: baseline;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%; }
  .magazine--icon--calendar {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 19'%3E%3Cpath d='M19.333 1.115c.916 0 1.667.69 1.667 1.533v13.027c0 .844-.751 1.533-1.667 1.533H2.667C1.75 17.207 1 16.518 1 15.674V2.648c0-.843.75-1.533 1.667-1.533h16.666zm0-.92H2.667C1.196.195 0 1.295 0 2.648v13.027c0 1.352 1.196 2.452 2.667 2.452h16.666c1.471 0 2.667-1.1 2.667-2.452V2.648C22 1.295 20.804.195 19.333.195z'/%3E%3Cpath d='M21 4.18V2.648c0-.843-.751-1.533-1.667-1.533H2.667C1.75 1.115 1 1.805 1 2.648V4.18l20-.001zM8.918 6.484H6.423V8.78h2.495V6.484zM15.577 6.484h-2.495V8.78h2.495V6.484zM18.907 6.484H16.41V8.78h2.497V6.484zM12.248 6.484H9.752V8.78h2.496V6.484zM8.918 9.545H6.423v2.295h2.495V9.545zM5.59 9.545H3.093v2.295H5.59V9.545zM15.577 9.545h-2.495v2.295h2.495V9.545zM18.907 9.545H16.41v2.295h2.497V9.545zM12.248 9.545H9.752v2.295h2.496V9.545zM8.918 12.607H6.423v2.295h2.495v-2.295zM5.59 12.607H3.093v2.295H5.59v-2.295zM15.577 12.607h-2.495v2.295h2.495v-2.295zM12.248 12.607H9.752v2.295h2.496v-2.295z'/%3E%3C/svg%3E"); }

.blog-detail {
  margin-top: 60px; }
  @media (min-width: 1200px) {
    .blog-detail {
      margin-top: 120px; } }

/*** IMPORT AREAS ***/
.area {
  margin-top: 30px; }
  @media screen and (min-width: 768px) {
    .area {
      margin-top: calc(30px + 30 * ((100vw - 768px) / 432)); } }
  @media screen and (min-width: 1200px) {
    .area {
      margin-top: 60px; } }

.area {
  margin-bottom: 30px; }
  @media screen and (min-width: 768px) {
    .area {
      margin-bottom: calc(30px + 30 * ((100vw - 768px) / 432)); } }
  @media screen and (min-width: 1200px) {
    .area {
      margin-bottom: 60px; } }

.area.margin-top--none {
  margin-top: 0; }

.area.margin-top--tiny {
  margin-top: 10px; }

.area.margin-top--small {
  margin-top: 20px; }

.area.margin-top--medium {
  margin-top: 30px; }
  @media screen and (min-width: 768px) {
    .area.margin-top--medium {
      margin-top: calc(30px + 30 * ((100vw - 768px) / 432)); } }
  @media screen and (min-width: 1200px) {
    .area.margin-top--medium {
      margin-top: 60px; } }

.area.margin-top--large {
  margin-top: 50px; }
  @media screen and (min-width: 768px) {
    .area.margin-top--large {
      margin-top: calc(50px + 50 * ((100vw - 768px) / 432)); } }
  @media screen and (min-width: 1200px) {
    .area.margin-top--large {
      margin-top: 100px; } }

.area.margin-top--xlarge {
  margin-top: 65px; }
  @media screen and (min-width: 768px) {
    .area.margin-top--xlarge {
      margin-top: calc(65px + 60 * ((100vw - 768px) / 432)); } }
  @media screen and (min-width: 1200px) {
    .area.margin-top--xlarge {
      margin-top: 125px; } }

.area.margin-bottom--none {
  margin-bottom: 0; }

.area.margin-bottom--tiny {
  margin-bottom: 10px; }

.area.margin-bottom--small {
  margin-bottom: 20px; }

.area.margin-bottom--medium {
  margin-bottom: 30px; }
  @media screen and (min-width: 768px) {
    .area.margin-bottom--medium {
      margin-bottom: calc(30px + 30 * ((100vw - 768px) / 432)); } }
  @media screen and (min-width: 1200px) {
    .area.margin-bottom--medium {
      margin-bottom: 60px; } }

.area.margin-bottom--large {
  margin-bottom: 50px; }
  @media screen and (min-width: 768px) {
    .area.margin-bottom--large {
      margin-bottom: calc(50px + 50 * ((100vw - 768px) / 432)); } }
  @media screen and (min-width: 1200px) {
    .area.margin-bottom--large {
      margin-bottom: 100px; } }

.area.margin-bottom--xlarge {
  margin-bottom: 65px; }
  @media screen and (min-width: 768px) {
    .area.margin-bottom--xlarge {
      margin-bottom: calc(65px + 60 * ((100vw - 768px) / 432)); } }
  @media screen and (min-width: 1200px) {
    .area.margin-bottom--xlarge {
      margin-bottom: 125px; } }

.area > *:first-child {
  margin-top: 0; }

.area > *:last-child {
  margin-bottom: 0; }

.area.is-narrow {
  margin-left: auto;
  margin-right: auto;
  max-width: 60em; }

.area.full-width {
  position: relative;
  width: 100vw;
  width: calc(100vw - var(--scrollbarWidth));
  left: 50%;
  transform: translateX(-50%); }

.area--thumbnail img, .area--thumbnail video {
  display: block;
  width: 100%;
  height: auto; }

.area--text h1, .area--text h2, .area--text h3, .area--text h4, .area--text h5, .area--text h6,
.area--text .h1, .area--text .h2, .area--text .h3, .area--text .h4, .area--text .h5, .area--text .h6 {
  margin-bottom: .5em; }

.area--textmedia {
  padding: 60px 0; }
  @media (max-width: 767.98px) {
    .area--textmedia {
      padding: 30px 0; } }
  .area--textmedia .area--thumbnail {
    margin: 0;
    z-index: 2;
    position: relative;
    min-height: 300px; }
    .area--textmedia .area--thumbnail img {
      position: absolute;
      height: 100%;
      object-fit: cover;
      min-height: 300px;
      max-height: 540px; }
    .area--textmedia .area--thumbnail--copyright, .area--textmedia .area--thumbnail--caption {
      display: block;
      position: relative;
      bottom: -100%; }
  .area--textmedia .area--text {
    position: relative;
    z-index: 1; }
    @media (max-width: 767.98px) {
      .area--textmedia .area--text {
        margin: 0 15px; } }
    .area--textmedia .area--text--inner {
      padding-top: 30px; }
      @media (min-width: 768px) {
        .area--textmedia .area--text--inner {
          padding-top: 0;
          padding-right: 60px; } }
      .area--textmedia .area--text--inner h2 {
        margin-bottom: 30px;
        word-break: break-word; }
      .area--textmedia .area--text--inner .btn {
        margin-top: 30px; }
      .area--textmedia .area--text--inner > *:first-child {
        margin-top: 0; }
      .area--textmedia .area--text--inner > *:last-child {
        margin-bottom: 0; }
    .area--textmedia .area--text:before {
      content: '';
      position: absolute;
      left: -30px;
      right: -30px;
      top: -50px;
      bottom: -30px;
      border: 1px dotted #000;
      z-index: -1; }
      @media (min-width: 768px) {
        .area--textmedia .area--text:before {
          right: 0;
          top: -60px;
          bottom: -60px;
          left: -140px; } }
    .area--textmedia .area--text.grass p, .area--textmedia .area--text.grass li, .area--textmedia .area--text.grass h3 {
      color: #fff !important; }
    .area--textmedia .area--text.grass:before {
      background-image: url("../images/layout/jpg/img_gras_ohne.jpg"); }
  .area--textmedia.full-width .area--text--inner {
    padding: 30px; }
    @media (min-width: 1200px) {
      .area--textmedia.full-width .area--text--inner {
        padding: 12% 15%; } }
  .area--textmedia.full-width .area--thumbnail {
    position: relative;
    margin-bottom: 0;
    overflow: hidden; }
    @media (min-width: 768px) {
      .area--textmedia.full-width .area--thumbnail {
        width: 100%;
        height: 100%; }
        .area--textmedia.full-width .area--thumbnail:before {
          display: block;
          content: '';
          width: 100%;
          height: 0;
          padding-bottom: 66.6666%; }
        .area--textmedia.full-width .area--thumbnail img, .area--textmedia.full-width .area--thumbnail video {
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          max-width: none;
          height: 100%;
          object-fit: cover; } }
  .area--textmedia.is-highlighted {
    position: relative;
    width: 100vw;
    width: calc(100vw - var(--scrollbarWidth));
    left: 50%;
    transform: translateX(-50%);
    background: #81AFAD; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .area--textmedia.is-highlighted > .cols > .col.area--text {
        padding-left: 30px; } }
    @media (max-width: 575.98px) {
      .area--textmedia.is-highlighted > .cols > .col.area--text {
        padding-left: 0; } }
    .area--textmedia.is-highlighted > .cols > .col.thumbnail--right.area--text {
      padding: 0 150px 0 0; }
      @media (min-width: 768px) and (max-width: 991.98px) {
        .area--textmedia.is-highlighted > .cols > .col.thumbnail--right.area--text {
          padding-right: 30px; } }
      @media (max-width: 575.98px) {
        .area--textmedia.is-highlighted > .cols > .col.thumbnail--right.area--text {
          padding-right: 0; } }
    .area--textmedia.is-highlighted .area--text {
      color: #fff; }
      .area--textmedia.is-highlighted .area--text--inner {
        padding: 30px; }
        @media (min-width: 1200px) {
          .area--textmedia.is-highlighted .area--text--inner {
            padding: 12% 15%; } }
      .area--textmedia.is-highlighted .area--text a:not(.btn) {
        color: #fff; }
        .area--textmedia.is-highlighted .area--text a:not(.btn):hover, .area--textmedia.is-highlighted .area--text a:not(.btn):focus {
          color: rgba(255, 255, 255, 0.8); }
      .area--textmedia.is-highlighted .area--text .btn-primary, .area--textmedia.is-highlighted .area--text .btn-primary:before {
        border-color: #fff; }
  .area--textmedia.text-center-vertically .area--text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; }
  .area--textmedia .thumbnail--right [class*="col"]:first-child {
    order: 2; }
  .area--textmedia .thumbnail--right [class*="col"]:last-child {
    order: 1; }
  @media (min-width: 768px) {
    .area--textmedia .thumbnail--right .area--text--inner {
      padding-left: 60px;
      padding-right: 0; } }
  .area--textmedia .thumbnail--right .area--text:before {
    bottom: -50px;
    top: 0; }
    @media (min-width: 768px) {
      .area--textmedia .thumbnail--right .area--text:before {
        right: -140px;
        top: -60px;
        bottom: -60px;
        left: 0; } }
  .area--textmedia > .cols--6 {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 0; }
    @media (min-width: 768px) {
      .area--textmedia > .cols--6 {
        grid-template-columns: repeat(6, 1fr); } }
    @media (max-width: 767.98px) {
      .area--textmedia > .cols--6 > [class*="col-"] {
        grid-column: auto / auto; } }
  @media (min-width: 1200px) {
    .area--textmedia .cols {
      grid-column-gap: 80px; } }
  @media (max-width: 767.98px) {
    .area--textmedia .cols--2 {
      grid-template-columns: 1fr; } }

.area--media .area--thumbnail {
  margin: 0; }

.area--parallax-media {
  position: relative;
  overflow: hidden;
  width: 100%; }
  .area--parallax-media {
    height: 610px; }
    @media screen and (min-width: 768px) {
      .area--parallax-media {
        height: calc(610px + 140 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .area--parallax-media {
        height: 750px; } }
  .area--parallax-media img {
    width: 100vmax;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    pointer-events: none;
    object-fit: cover;
    filter: brightness(0.6); }
  .area--parallax-media picture {
    position: absolute;
    height: 100%;
    width: 100%; }
  .area--parallax-media figure {
    height: 100%; }

.area--parallax-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff; }
  .area--parallax-text h2 {
    font-size: 55px; }
    @media screen and (min-width: 768px) {
      .area--parallax-text h2 {
        font-size: calc(55px + 45 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .area--parallax-text h2 {
        font-size: 100px; } }
  @media (max-width: 767.98px) {
    .area--parallax-text {
      left: unset;
      transform: translate(0, -50%); } }

.area--teaser {
  position: relative; }
  @media (max-width: 575.98px) {
    .area--teaser.slider {
      width: 100vw;
      width: calc(100vw - var(--scrollbarWidth));
      left: 50%;
      transform: translateX(-50%); } }
  .area--teaser.slider .teaser {
    height: 100%; }
  .area--teaser .teaser--inner {
    height: 100%;
    position: relative;
    transition: background-color 0.3s;
    /* &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 30px;
                right: 30px;
                height: 1px;
                border-bottom: 1px dashed $black;

                @include media-breakpoint-down(sm) {
                    display: none;
                }
            } */ }
    .area--teaser .teaser--inner:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 15px;
      top: 0;
      right: -15px;
      border-right: 1px dashed #000; }
      @media (max-width: 575.98px) {
        .area--teaser .teaser--inner:after {
          display: none; } }
    .area--teaser .teaser--inner .teaser--text, .area--teaser .teaser--inner .teaser--text a:not(.btn) {
      color: #000 !important; }
    .area--teaser .teaser--inner:hover {
      cursor: pointer;
      background-color: #81AFAD; }
      .area--teaser .teaser--inner:hover .teaser--text, .area--teaser .teaser--inner:hover .teaser--text a:not(.btn) {
        color: #fff !important; }
        .area--teaser .teaser--inner:hover .teaser--text li, .area--teaser .teaser--inner:hover .teaser--text a:not(.btn) li {
          border-color: #fff; }
      .area--teaser .teaser--inner:hover h4 {
        color: #fff; }
      .area--teaser .teaser--inner:hover .btn-secondary {
        background-color: #fff !important;
        color: #81AFAD !important; }
      .area--teaser .teaser--inner:hover .yala {
        background-color: #00A94F; }
        .area--teaser .teaser--inner:hover .yala i {
          filter: saturate(0) brightness(0) invert(100%) opacity(1); }
  .area--teaser .teaser header {
    margin-bottom: 15px; }
    @media screen and (min-width: 768px) {
      .area--teaser .teaser header {
        margin-bottom: calc(15px + 5 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .area--teaser .teaser header {
        margin-bottom: 20px; } }
  .area--teaser .teaser header h4 {
    word-break: break-word;
    color: #CC0000; }
  .area--teaser .teaser--image {
    position: relative; }
    .area--teaser .teaser--image img {
      width: 100%;
      height: 270px;
      max-width: none;
      object-fit: cover; }
      @media (max-width: 575.98px) {
        .area--teaser .teaser--image img {
          height: 180px; } }
  .area--teaser .teaser--text {
    padding: 0 30px 30px 30px;
    height: calc(100% - 270px);
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .area--teaser .teaser--text {
      padding-top: 45px; }
      @media screen and (min-width: 768px) {
        .area--teaser .teaser--text {
          padding-top: calc(45px + 5 * ((100vw - 768px) / 432)); } }
      @media screen and (min-width: 1200px) {
        .area--teaser .teaser--text {
          padding-top: 50px; } }
    @media (max-width: 575.98px) {
      .area--teaser .teaser--text {
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        height: calc(100% - 180px); } }
    .area--teaser .teaser--text p {
      margin-bottom: 0; }
  .area--teaser .teaser .btn {
    min-width: 0;
    animation: none;
    display: block;
    text-align: center; }
    .area--teaser .teaser .btn {
      margin-top: 25px; }
      @media screen and (min-width: 768px) {
        .area--teaser .teaser .btn {
          margin-top: calc(25px + 5 * ((100vw - 768px) / 432)); } }
      @media screen and (min-width: 1200px) {
        .area--teaser .teaser .btn {
          margin-top: 30px; } }
  .area--teaser .teaser--information {
    margin-top: 15px; }
    @media screen and (min-width: 768px) {
      .area--teaser .teaser--information {
        margin-top: calc(15px + 15 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .area--teaser .teaser--information {
        margin-top: 30px; } }
  .area--teaser .teaser--information li {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px dashed #000; }
    .area--teaser .teaser--information li p {
      flex: 0 0 50%;
      margin-bottom: 0; }
      .area--teaser .teaser--information li p:first-child {
        padding-right: 10px; }
  .area--teaser .teaser--price {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 30px;
    background-color: #CC0000;
    padding: 10px 20px 10px 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px; }
    .area--teaser .teaser--price p {
      color: #fff;
      margin-bottom: 0;
      font-size: 12px;
      line-height: 16px; }
      .area--teaser .teaser--price p strong {
        font-size: 25px; }
  .area--teaser .teaser-logos {
    display: flex;
    gap: 20px;
    padding-left: 30px;
    position: absolute;
    bottom: -30px; }
    .area--teaser .teaser-logos .c-share {
      animation: none; }
  .area--teaser .slider--controls {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 50%;
    transform: translateY(-50%); }
    .area--teaser .slider--controls {
      left: -50px; }
      @media screen and (min-width: 1260px) {
        .area--teaser .slider--controls {
          left: calc(-50px + -80 * ((100vw - 1260px) / 140)); } }
      @media screen and (min-width: 1400px) {
        .area--teaser .slider--controls {
          left: -130px; } }
    .area--teaser .slider--controls {
      right: -50px; }
      @media screen and (min-width: 1260px) {
        .area--teaser .slider--controls {
          right: calc(-50px + -80 * ((100vw - 1260px) / 140)); } }
      @media screen and (min-width: 1400px) {
        .area--teaser .slider--controls {
          right: -130px; } }
    @media (max-width: 575.98px) {
      .area--teaser .slider--controls {
        display: none; } }
    .area--teaser .slider--controls .arrow {
      height: 60px;
      width: 60px;
      background-color: #81AFAD;
      border: none;
      border-radius: 100%;
      cursor: pointer; }
      .area--teaser .slider--controls .arrow--prev {
        transform: scaleX(-1); }
    .area--teaser .slider--controls svg {
      filter: saturate(0) brightness(0) invert(100%) opacity(1);
      height: 20px;
      width: 20px; }
  @media (max-width: 575.98px) {
    .area--teaser .splide__slide {
      padding-bottom: 30px; } }
  .area--teaser .splide__pagination li {
    margin: 0 20px; }
    .area--teaser .splide__pagination li button {
      height: 10px;
      width: 10px;
      border-radius: 100%;
      border-color: transparent; }
      .area--teaser .splide__pagination li button.is-active {
        height: 15px;
        width: 15px;
        background-color: #000; }
  .area--teaser:not(.slider) {
    overflow: hidden; }

.teaser-header {
  position: absolute;
  top: 160px;
  width: 100%;
  z-index: 1; }
  @media (max-width: 1199.98px) {
    .teaser-header {
      top: 90px; } }
  @media (max-width: 991.98px) {
    .teaser-header {
      display: none; } }
  .teaser-header .area--teaser {
    max-width: 1140px;
    margin: 0 auto;
    padding-right: 60px; }
  .teaser-header .teaser {
    max-width: 360px;
    background-color: #fff;
    margin-right: 0;
    margin-left: auto; }
    @media (max-width: 1199.98px) {
      .teaser-header .teaser {
        margin: 0 auto; } }
    .teaser-header .teaser--inner:before, .teaser-header .teaser--inner:after {
      content: none; }
    .teaser-header .teaser--image {
      display: none; }

.area--teaser-container .area--teaser {
  max-width: 1140px;
  margin: 0 auto;
  padding-right: 60px; }

.area--teaser-container .teaser {
  width: 360px;
  background-color: #fff;
  margin-right: 0;
  margin-left: auto; }
  @media (max-width: 1199.98px) {
    .area--teaser-container .teaser {
      margin: 0 auto; } }
  .area--teaser-container .teaser--inner:before, .area--teaser-container .teaser--inner:after {
    content: none; }
  .area--teaser-container .teaser--image {
    display: none; }

.microsite .area--teaser .teaser-inner:hover, .microsite .area--teaser .slider--controls .arrow {
  background-color: var(--micrositeAreaPrimaryColor); }

.area--content-container {
  background: #F3F8F9;
  color: #000;
  position: relative; }
  .area--content-container {
    padding-top: 60px; }
    @media screen and (min-width: 768px) {
      .area--content-container {
        padding-top: calc(60px + 40 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .area--content-container {
        padding-top: 100px; } }
  .area--content-container {
    padding-bottom: 60px; }
    @media screen and (min-width: 768px) {
      .area--content-container {
        padding-bottom: calc(60px + 40 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .area--content-container {
        padding-bottom: 100px; } }
  @media (min-width: 768px) {
    .area--content-container h2.margin-top {
      margin-top: 50px; } }

.microsite .area--content-container {
  background: var(--micrositeAreaSecondaryColor); }

.area--teaser-container {
  position: relative;
  min-height: 450px;
  display: flex; }
  .area--teaser-container .container-teaser {
    flex: 1 1 auto;
    padding-right: 30px; }
    @media (max-width: 1199.98px) {
      .area--teaser-container .container-teaser {
        max-width: unset;
        padding-right: 0; } }
  .area--teaser-container .teaser-parent {
    flex: 0 0 auto; }
    .area--teaser-container .teaser-parent .area--teaser {
      position: sticky;
      top: 200px;
      padding-right: 0; }
  .area--teaser-container .area--teaser {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1); }
    @media (max-width: 1199.98px) {
      .area--teaser-container .area--teaser {
        display: none; } }

.area--icon-teaser article {
  margin-left: 30px;
  padding: 30px 30px 30px 45px;
  position: relative;
  background-color: #fff; }
  .area--icon-teaser article h4 {
    margin-bottom: 10px; }
  @media (min-width: 1200px) {
    .area--icon-teaser article {
      margin-left: 0;
      margin-top: 55px;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 80px;
      padding-bottom: 60px;
      text-align: center; }
      .area--icon-teaser article h4 {
        margin-bottom: 30px; } }
  .area--icon-teaser article span {
    height: 60px;
    width: 60px;
    background-color: #81AFAD;
    border-radius: 100%;
    position: absolute;
    left: -30px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .area--icon-teaser article span i {
      height: 2.3em;
      width: 2.3em; }
    @media (min-width: 1200px) {
      .area--icon-teaser article span {
        height: 110px;
        width: 110px;
        left: 50%;
        transform: translateX(-50%);
        top: -55px; }
        .area--icon-teaser article span i {
          height: 3.3em;
          width: 3.3em; } }

@media (max-width: 575.98px) {
  .area--icon-teaser .cols {
    grid-row-gap: 15px;
    grid-colum-gap: 15px; } }

@media (min-width: 1200px) {
  .area--icon-teaser .cols--3 {
    grid-template-columns: repeat(3, 1fr); } }

@media (min-width: 576px) and (max-width: 1199.98px) {
  .area--icon-teaser .cols--3 {
    grid-template-columns: repeat(2, 1fr); } }

@media (min-width: 1200px) {
  .area--icon-teaser .cols--4 {
    grid-template-columns: repeat(4, 1fr); } }

@media (min-width: 576px) and (max-width: 1199.98px) {
  .area--icon-teaser .cols--4 {
    grid-template-columns: repeat(2, 1fr); } }

.col .area--icon-teaser {
  margin: 0; }

.microsite .area--icon-teaser article span {
  background-color: var(--micrositeAreaPrimaryColor); }

.area--disruptor-media {
  position: relative;
  padding: 60px; }
  .area--disruptor-media {
    height: 375px; }
    @media screen and (min-width: 768px) {
      .area--disruptor-media {
        height: calc(375px + 595 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .area--disruptor-media {
        height: 970px; } }
  @media (max-width: 991.98px) {
    .area--disruptor-media {
      padding-bottom: 0; } }
  @media (max-width: 767.98px) {
    .area--disruptor-media {
      padding: 0; } }
  .area--disruptor-media .area--thumbnail {
    height: 100%; }
    .area--disruptor-media .area--thumbnail img,
    .area--disruptor-media .area--thumbnail picture,
    .area--disruptor-media .area--thumbnail video,
    .area--disruptor-media .area--thumbnail iframe {
      height: 100%;
      width: 100%;
      object-fit: cover; }

.area--disruptor-text {
  max-width: 555px;
  padding: 60px 0;
  position: absolute;
  bottom: 0;
  left: 160px;
  background-color: #fff;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.1); }
  @media (max-width: 991.98px) {
    .area--disruptor-text {
      position: relative;
      left: unset;
      margin: 0 auto;
      bottom: 0; } }
  @media (max-width: 767.98px) {
    .area--disruptor-text {
      padding: 60px 0 80px 0; } }
  .area--disruptor-text h4 {
    margin-bottom: 30px;
    color: #CC0000; }
  .area--disruptor-text .btn {
    margin-top: 30px; }
  .area--disruptor-text.margin-badge {
    padding-top: 140px; }
    @media (min-width: 992px) {
      .area--disruptor-text.margin-badge {
        padding-top: 60px; } }

.area--disruptor-badge {
  background-image: svg-load("../images/layout/svg/disruptor-badge.svg");
  background-size: contain;
  height: 270px;
  width: 270px;
  position: absolute;
  bottom: -135px;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  @media (min-width: 992px) {
    .area--disruptor-badge {
      top: 160px;
      right: 160px;
      left: unset;
      transform: unset; } }
  .area--disruptor-badge-text {
    transform: rotate(-5deg);
    display: flex;
    align-items: center;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    padding: 10px 25px 20px;
    color: #81AFAD;
    margin-bottom: 0; }
  .area--disruptor-badge-arrow {
    background-image: svg-load("../images/layout/svg/disruptor-arrow.svg");
    background-repeat: no-repeat;
    background-size: contain;
    height: 20px;
    width: 100%;
    transform: rotate(-5deg);
    background-position: center; }

.area--disruptor .special-headline {
  position: absolute;
  left: 150px;
  top: 130px;
  color: #fff; }
  @media (max-width: 991.98px) {
    .area--disruptor .special-headline {
      left: 0;
      width: 100%;
      text-align: center;
      top: 100px; } }

.area--disruptor .area--thumbnail--copyright {
  display: block;
  text-align: end; }

.area--disruptor .area--thumbnail--caption {
  text-align: end; }

@media (max-width: 1199.98px) {
  .area--disruptor .area--thumbnail--caption, .area--disruptor .area--thumbnail--copyright {
    position: relative;
    top: -100%;
    color: #fff;
    display: block;
    text-align: start; } }

.area--accordion label {
  cursor: pointer; }

.area--accordion section {
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center; }

.area--accordion-container {
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 1.5rem; }

.area--accordion .accordion-item {
  background-color: transparent;
  border-radius: .4rem; }
  .area--accordion .accordion-item hr {
    border: 1px dashed #000;
    margin: 0 !important; }

.area--accordion .accordion-link {
  font-size: 1.6rem;
  color: #000;
  text-decoration: none;
  background-color: transparent;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 20px; }
  @media (max-width: 575.98px) {
    .area--accordion .accordion-link {
      padding: 1rem 15px; } }
  .area--accordion .accordion-link h3 {
    font-weight: 500;
    font-size: 20px;
    padding-right: 10px; }
  .area--accordion .accordion-link i {
    color: #000;
    padding: .5rem; }
  .area--accordion .accordion-link ul {
    display: flex;
    align-items: flex-end;
    list-style-type: none;
    margin-left: 25px; }
    .area--accordion .accordion-link ul li {
      font-size: 10px;
      color: #000;
      padding: 0 0 1px 5px; }
  .area--accordion .accordion-link div {
    display: flex; }

.area--accordion .accordion--angle {
  height: 30px;
  width: 30px;
  min-width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 100%; }
  .area--accordion .accordion--angle span {
    margin: 0;
    height: 8px;
    width: 8px;
    transition: transform 0.3s; }

.area--accordion .answer {
  max-height: 0;
  overflow: hidden;
  position: relative;
  background-color: transparent;
  transition: max-height 0.6s;
  padding: 0 20px; }
  @media (max-width: 575.98px) {
    .area--accordion .answer {
      padding: 0 15px; } }
  .area--accordion .answer p {
    margin-bottom: 0; }

.area--accordion .area--text {
  margin-top: 0 !important;
  margin-bottom: 1rem !important; }

.area--accordion [name="toogleAccordion"]:checked ~ .answer {
  max-height: 200rem;
  transition: max-height 3s; }

.area--accordion [name="toogleAccordion"]:checked ~ .accordion-link .accordion--angle {
  border-color: #000; }
  .area--accordion [name="toogleAccordion"]:checked ~ .accordion-link .accordion--angle span {
    border-color: #000;
    opacity: 1;
    transform: rotate(135deg); }

.area--accordion [name="toogleAccordion"] {
  display: none; }

.area--quote .quote {
  color: #445572;
  text-align: center; }

.area--quote h4 {
  font-family: "Sedgwick Ave", serif; }
  .area--quote h4 {
    line-height: 35px; }
    @media screen and (min-width: 768px) {
      .area--quote h4 {
        line-height: calc(35px + 5 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .area--quote h4 {
        line-height: 40px; } }
  .area--quote h4 {
    margin-bottom: 20px; }
    @media screen and (min-width: 768px) {
      .area--quote h4 {
        margin-bottom: calc(20px + 10 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .area--quote h4 {
        margin-bottom: 30px; } }
  .area--quote h4:before {
    content: svg-load("../images/layout/svg/quote.svg");
    display: block; }

.area--quote p {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  display: inline-block;
  position: relative; }
  .area--quote p span {
    background-color: #fff;
    padding: 0 10px; }
  .area--quote p:after {
    content: '';
    height: 1px;
    background-color: #445572;
    left: -30px;
    right: -30px;
    position: absolute;
    top: 50%;
    z-index: -1; }

@media (max-width: 575.98px) {
  .id-136 .container {
    padding-left: 15px;
    padding-right: 15px; } }

@media (max-width: 991.98px) {
  .id-136 .cols--2.ratio--60-40 {
    grid-template-columns: 1fr; } }

.id-147 .main-content {
  padding-bottom: 0 !important; }

.id-2 .area--html img, .id-98 .area--html img {
  margin-right: 25px; }

@media (min-width: 768px) {
  .id-165 .header-media {
    height: 85vh !important; } }

@media (max-width: 767.98px) {
  .travel-data-table td:first-child {
    width: 100% !important; }
  .travel-data-table td:before {
    display: none; }
  .form-line {
    padding-left: 28px !important;
    padding-right: 28px !important; } }
